/* eslint-disable */
export default {
  id: "solaceGoAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace Go",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Go",
    logo: "./images/go-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/go",
    api:
      "https://docs.solace.com/Solace-PubSub-Messaging-APIs/Go-API/go-home.htm",
    download: [
      {
        name: "Solace Downloads",
        url: "https://solace.com/downloads/",
      },
    ],
  },
  step1: {
    name: "Go API",
    getAPI: [
      {
        title: "Using go get",
        code: ["go get solace.dev/go/messaging"],
      },
      {
        title: "Using the Solace Developer Portal",
        description:
          'The Solace SMF Go API can be downloaded <a href="https://solace.com/downloads/?fwp_downloads=solace-apis" target="_blank">here</a>.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must build a Messaging Service",
      "The Solace Messaging Service is the basis for all client communication with Solace messaging.",
    ],
    code: [
      {
        protocol: "SMF",
      },
      {
        protocol: "Secured SMF",
      },
      {
        protocol: "Compressed SMF",
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://github.com/SolaceSamples/solace-samples-go/blob/main/patterns/hello_world.go",
      },
      {
        id: "guaranteed-publisher",
        name: "Guaranteed <br>\
                        Publisher",
        logo: "./images/persistence-queues.svg",
        url:
          "https://github.com/SolaceSamples/solace-samples-go/blob/main/patterns/guaranteed_publisher.go",
      },
      {
        id: "guaranteed-subscriber",
        name: "Guaranteed <br>\
                        Subscriber",
        logo: "./images/persistence-queues.svg",
        url:
          "https://github.com/SolaceSamples/solace-samples-go/blob/main/patterns/guaranteed_subscriber.go",
      },
    ],
  },
}
