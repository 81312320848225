import React from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"
import pubCode from "./code-samples-pub-json"

export default class CodeSamplesPub extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: 0,
      copied: false,
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  copyCode(elementId) {
    let codeElement = document.getElementById(elementId)
    var tempCodeElement = document.createElement("textarea")
    document.body.appendChild(tempCodeElement)
    tempCodeElement.value = codeElement.innerHTML.replace(
      /<br\s*[/]?>/gi,
      "\r\n"
    )
    tempCodeElement.select()
    document.execCommand("copy", false)
    this.setState({ copied: !this.state.copied })
    tempCodeElement.remove()
    setTimeout(() => {
      this.setState({ copied: !this.state.copied })
    }, 1000)
  }

  createMarkup(dangerousHTML) {
    return { __html: dangerousHTML }
  }

  render() {
    return (
      <div>
        <Container fluid={true}>
          <Nav tabs>
            {pubCode.map((item, index) => {
              return (
                <NavItem key={item.id}>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === index,
                    })}
                    onClick={() => {
                      this.toggle(index)
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
        </Container>
        <TabContent activeTab={this.state.activeTab}>
          {pubCode.map((item, index) => {
            return (
              <TabPane tabId={index} key={item.id}>
                <Container fluid={true}>
                  <Row>
                    <Col sm="12">
                      <div className="sample">
                        <button
                          onClick={() => this.copyCode("pub-code-" + item.id)}
                          className="tooltip copy"
                          aria-label="Copy Code"
                        >
                          {this.state.copied ? (
                            <span className="tooltiptext">Copied!</span>
                          ) : (
                            <span className="tooltiptext">Copy Code</span>
                          )}
                          <i className="fa fa-copy"></i>
                        </button>
                        <div
                          className="code"
                          id={"pub-code-" + item.id}
                          dangerouslySetInnerHTML={this.createMarkup(item.code)}
                        ></div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </TabPane>
            )
          })}
        </TabContent>
      </div>
    )
  }
}
