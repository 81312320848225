/* eslint-disable */
export default {
  id: "solace-amqp10-api",
  componentName: "connectivity-detail-template-v1",
  displayName: "AMQP10 Open Source API",
  protocol: {
    id: "amqp",
    name: "AMQP",
    logo: "./images/amqp.png",
    usesVPN: false,
  },
  language: {
    name: "Node.js",
    logo: "./images/nodejs-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/nodejs-amqp/",
    api: "https://github.com/noodlefrenzy/node-amqp10/blob/master/README.md",
    download: [
      {
        name: "GitHub",
        url: "https://github.com/noodlefrenzy/node-amqp10",
      },
    ],
  },
  step1: {
    name: "Node.js API",
    getAPI: [
      {
        title: "Using NPM",
        code: ["npm install amqp10 -save"],
      },
    ],
  },
  step2: {
    introduction: [
      "The amqp10 client uses Promise from the Bluebird library that is a superset of the ES6 Promise specification, but our tutorial examples will follow only the ES6 Promise specification.",
      "In order to send or receive messages, an application that uses the amqp10 client must start a connection to the Solace messaging AMQP service URL. The URL consists of the Solace username, password, and host name with the AMQP service port number in the form amqp://<username>:<password>@<host:port>",
      "Assigning defaultSubjects to false allows the use of a slash-separated hierarchy in the queue name.",
    ],
    code: [
      {
        protocol: "AMQP",
        sample:
          ' \
               var AMQP = require("amqp10"); <br>\
               <br>\
               var amqpClient = new AMQP.Client(AMQP.Policy.merge({ <br>\
               &nbsp; defaultSubjects : false <br>\
               })); <br>\
               <br>\
               amqpClient.connect("amqp://<span class="value">${username}</span>:<span class="value">${password}</span>@<span class="value">${host}</span>").then(() => { <br>\
                   &nbsp; console.log("Connected") <br>\
               }); <br>\
               ',
      },
      {
        protocol: "Secured AMQP",
        sample:
          ' \
               var AMQP = require("amqp10"); <br>\
               <br>\
               var amqpClient = new AMQP.Client(AMQP.Policy.merge({ <br>\
               &nbsp; defaultSubjects : false <br>\
               })); <br>\
               <br>\
               amqpClient.connect("amqp://<span class="value">${username}</span>:<span class="value">${password}</span>@<span class="value">${host}</span>").then(() => { <br>\
                   &nbsp; console.log("Connected") <br>\
               }); <br>\
               ',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "persistence-queues",
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url:
          "https://tutorials.solace.dev/nodejs-amqp/persistence-with-queues/",
      },
      {
        id: "confirmed-delivery",
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url:
          "https://tutorials.solace.dev/nodejs-amqp/confirmed-delivery/",
      },
      {
        id: "topic-mapping",
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url:
          "https://tutorials.solace.dev/nodejs-amqp/topic-to-queue-mapping/",
      },
    ],
  },
}
