/* eslint-disable */
const subCode = [
  {
    name: "Java",
    id: "java-sub",
    code: 'MessagingService messagingService = MessagingService.builder(ConfigurationProfile.V1)<br>\
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.fromProperties(properties).build().connect();<br>\
    Topic topic = Topic.of("solace/try/>");<br>\
    DirectMessageReceiver receiver = messagingService.createDirectMessageReceiverBuilder()<br>\
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.withSubscriptions(topic).build().start();<br>\
    receiver.receiveAsync(messageHandler);<br>'
  },
  {
    name: "Python",
    id: "python-sub",
    code: 'topic = Topic.of("solace/try/>") <br>\
    messaging_service = MessagingService.builder().from_properties(broker_props).build().connect() <br>\
    direct_receiver = messaging_service.create_direct_message_receiver_builder().with_subscriptions(topic).build().start() <br>\
    direct_receiver.receive_async(MessageHandlerImpl())'

  },
  {
    name: "Go",
    id: "go-sub",
    code: 'topic := resource.TopicSubscriptionOf("solace/try/>") <br>\
    messagingService, err := messaging.NewMessagingServiceBuilder().FromConfigurationProvider(brokerConfig).Build() <br>\
    directReceiver, err := messagingService.CreateDirectMessageReceiverBuilder().WithSubscriptions(topic).Build()<br>\
    err := directReceiver.Start()<br>\
    regErr := directReceiver.ReceiveAsync(MessageHandler)'

  }, 
  {
    name: "Spring",
    id: "scst-sub",
    code: '// Consume using Spring Cloud Stream <br>\
      @Bean <br>\
      public Consumer<String> helloWorldConsume(){ <br>\
        &nbsp; return v -> System.out.println("Received: " + v); <br>\
      }'
  }, 
  {
    name: "JS",
    id: "js-sub",
    code: 'subscriber.session.connect();<br>\
      subscriber.session.subscribe(<br>\
          solace.SolclientFactory.createTopicDestination("solace/try/>"),<br>\
          true, correlationObject, 10000<br>\
      );'
  },
  {
    name: "REST",
    id: "rest-sub",
    code: "http.createServer(function (req, res) {<br>\
    console.log('Received message: ' + req.url);<br>\
    res.writeHead(200);<br>\
    res.end();<br>\
    }).listen([PORT], '[HOST]');<br>\
    console.log('Server running at http://[HOST]:[PORT]/');"
  },
  {
    name: "MQTT",
    id: "mqtt-sub",
    code: 'mqttClient.connect(connOpts);<br>\
    mqttClient.setCallback(new PrintingMqttCallback());<br>\
    String topic = "solace/try/>";<br>\
    int qos = 0;<br>\
    mqttClient.subscribe(topic, qos);'
  }, {
    name: "JMS 2.0",
    id: "jms2.0-sub",
    code: 'JMSContext context = connectionFactory.createContext();<br>\
    Topic topic = context.createTopic("solace/try/>");<br>\
    JMSConsumer consumer = context.createConsumer(topic);<br>\
    String message = consumer.receiveBody(String.class);'
  }, {
    name: "JMS 1.1",
    id: "jms1.1-sub",
    code: 'Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);<br>\
    Topic topic = session.createTopic("solace/try/>");<br>\
    MessageConsumer messageConsumer = session.createConsumer(topic);<br>\
    messageConsumer.setMessageListener( new MessageDumpListener() );<br>\
    connection.start();'
  },
]

export default subCode
