/* eslint-disable */
export default {
  id: "solaceRest",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace REST Messaging API",
  protocol: {
    id: "rest",
    name: "REST",
    logo: "./images/solace-logo.png",
    usesVPN: false,
  },
  language: {
    name: "REST",
    logo: "./images/solace-logo.png",
  },
  links: {
    tutorial:
      "https://tutorials.solace.dev/rest-messaging/publish-subscribe/",
    api: "https://docs.solace.com/Open-APIs-Protocols/REST-get-start.htm",
    download: [],
  },
  step1: {
    name: "Solace REST messaging API",
    getAPI: [
      {
        title: "Using REST",
        description:
          'There is no API to obtain. The Solace REST messaging API is a wireline RESTful HTTP protocol. It is fully outlined in <a href="https://docs.solace.com/RESTMessagingPrtl/Solace-REST-Overview.htm" target="_blank">REST Messaging Protocol</a>.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to receive REST messages from Solace messaging, you must configure a Guaranteed messaging queue and a REST delivery point. The queue is used to attract messages to the consumer application. The REST delivery point is the Solace message router component that delivers the messages from the queue to the consumer application asynchronously through HTTP POST requests.",
    ],
    code: [
      {
        protocol: "REST",
        sample:
          'curl -X POST <span class="value">${url}</span>/T/rest/pubsub \\<br>\
                        -d "Hello World REST" \\<br>\
                        -H "content-type: text" \\<br>\
                        -H "Solace-delivery-mode: direct" \\<br>\
                        --user <span class="value">${username}</span>:<span class="value">${password}</span>',
      },
      {
        protocol: "Secured REST",
        sample:
          'curl -X POST <span class="value">${url}</span>/T/rest/pubsub \\<br>\
                        -d "Hello World REST" \\<br>\
                        -H "content-type: text" \\<br>\
                        -H "Solace-delivery-mode: direct" \\<br>\
                        --user <span class="value">${username}</span>:<span class="value">${password}</span>',
      },
    ],
    conclusion: [
      "At this point, the client has sent a message to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://tutorials.solace.dev/rest-messaging/publish-subscribe/",
      },
    ],
  },
}
