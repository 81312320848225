/* eslint-disable */
export default {
  id: "pahoJavaAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Eclipse Paho Java Client",
  protocol: {
    id: "mqtt",
    name: "MQTT",
    logo: "./images/paho.png",
    usesVPN: false,
  },
  language: {
    name: "Java",
    logo: "./images/java-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/java-mqtt-paho/",
    api: "http://www.eclipse.org/paho/files/javadoc/index.html",
    download: [
      {
        name: "Eclipse Paho",
        url: "https://www.eclipse.org/paho/clients/java/",
      },
    ],
  },
  step1: {
    name: "Eclipse Paho Java Client",
    getAPI: [
      {
        title: "Using Gradle",
        code: [
          'compile("org.eclipse.paho:org.eclipse.paho.client.mqttv3:1.1.0")',
        ],
      },
      {
        title: "Using Maven",
        code: [
          "&lt;dependency&gt; <br>\
                &nbsp&nbsp &lt;groupId&gt;org.eclipse.paho&lt/groupId&gt <br>\
                &nbsp&nbsp &lt;artifactId&gt;org.eclipse.paho.client.mqttv3&lt;/artifactId&gt; <br>\
                &nbsp&nbsp &lt;version&gt;1.1.0&lt;/version&gt; <br>\
                &lt;/dependency&gt",
        ],
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an MQTT client must connect a session.",
      "The MQTT session is the basis for all client communication with Solace messaging.",
    ],
    code: [
      {
        protocol: "MQTT",
        sample:
          '\
                MqttClient mqttClient = new MqttClient("<span class="value">${url}</span>", "HelloWorldSub"); <br>\
                MqttConnectOptions connectionOptions = new MqttConnectOptions(); <br>\
                connectionOptions.setUsername("<span class="value">${username}</span>"); <br>\
                connectionOptions.setPassword("<span class="value">${password}</span>".toCharArray()) <br>\
                mqttClient.connect(connectionOptions);',
      },
      {
        protocol: "Secured MQTT",
        sample:
          ' \
                MqttClient mqttClient = new MqttClient("<span class="value">${url}</span>", "HelloWorldSub"); <br>\
                MqttConnectOptions connectionOptions = new MqttConnectOptions(); <br>\
                connectionOptions.setUsername("<span class="value">${username}</span>"); <br>\
                connectionOptions.setPassword("<span class="value">${password}</span>".toCharArray()) <br>\
                mqttClient.connect(connectionOptions);',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://tutorials.solace.dev/java-mqtt-paho/publish-subscribe/",
      },
      {
        id: "persistence-queues",
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url:
          "https://tutorials.solace.dev/java-mqtt-paho/persistence-with-queues/",
      },
      {
        id: "request-reply",
        name: "Request/ <br>\
                        Reply",
        logo: "./images/request-reply.svg",
        url: "https://tutorials.solace.dev/java-mqtt-paho/request-reply/",
      },
      {
        id: "confirmed-delivery",
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url:
          "https://tutorials.solace.dev/java-mqtt-paho/confirmed-delivery/",
      },
      {
        id: "topic-mapping",
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url:
          "https://tutorials.solace.dev/java-mqtt-paho/topic-to-queue-mapping/",
      },
    ],
  },
}
