/* eslint-disable */
export default {
  id: "pahoDotNetAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Eclipse Paho .NET Client",
  protocol: {
    id: "mqtt",
    name: "MQTT",
    logo: "./images/paho.png",
    usesVPN: false,
  },
  language: {
    name: ".NET",
    logo: "./images/dotnet-logo.png",
  },
  links: {
    api: "https://m2mqtt.wordpress.com/m2mqtt_doc/",
    download: [
      {
        name: "Eclipse Paho Portal",
        url:
          "https://www.eclipse.org/downloads/download.php?file=/paho/1.2-milestones/m2mqtt/M2Mqtt_4.2.0.0.zip",
      },
    ],
  },
  step1: {
    name: "Eclipse Paho .NET Client",
    getAPI: [
      {
        title: "Download from Eclipse",
        description:
          'Get Eclipse Paho .NET Client from <a href="https://www.eclipse.org/downloads/download.php?file=/paho/1.2-milestones/m2mqtt/M2Mqtt_4.2.0.0.zip" target="_bank">here</a>',
      },
    ],
  },
}
