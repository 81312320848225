import React from "react"
import content from "../../data/content"

export default class Description extends React.Component {
  render() {
    return (
      <div className="row section-description">
        <div className="col-lg-5">
          <h2>{content.apisprotocols.title}</h2>
          <div className="text-area">
            <p>
              {content.apisprotocols.paragraph_1.p1_content}{" "}
              <a
                href="https://docs.solace.com/APIs.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                {content.apisprotocols.paragraph_1.p1_link}
              </a>{" "}
              {content.apisprotocols.paragraph_1.p1_1_content}
            </p>
            <p>{content.apisprotocols.paragraph_2.p2_content}</p>
          </div>
        </div>
        <div className="col-lg-7" style={{ textAlign: "center" }}>
          <img
            className="diagram"
            src={require("./images/solace-apis-protocols-support.png")}
            alt="Diagram: APIs and Protocols"
            width="730px"
            height="420px"
          />
        </div>
      </div>
    )
  }
}
