import React from "react"
import { Container, Row, Col, Card, CardTitle, CardText } from "reactstrap"
import content from "../../data/content"

const Resources = props => {
  return (
    <section className="resources DarkGreyGradient" id="Resources">
      <Container fluid={true}>
        <h2>{content.resources.title}</h2>
        <Row>
          <Col xs="12" md="4" lg="4">
            <a
              href="https://tutorials.solace.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>{content.resources.tutorials.title}</CardTitle>
                <CardText>{content.resources.tutorials.description}</CardText>
              </Card>
            </a>
            <a
              href="https://github.com/SolaceSamples"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>{content.resources.github.title}</CardTitle>
                <CardText>{content.resources.github.description}</CardText>
              </Card>
            </a>
            <a
              href="https://codelabs.solace.dev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>
                  {content.resources.codelabs_workshops.title}
                </CardTitle>
                <CardText>
                  {content.resources.codelabs_workshops.description}
                </CardText>
              </Card>
            </a>
          </Col>
          <Col xs="12" md="4" lg="4">
            <a
              href="https://training.solace.com/learn/signin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>
                  {content.resources.training_certification.title}
                </CardTitle>
                <CardText>
                  {content.resources.training_certification.description}
                </CardText>
              </Card>
            </a>
            <a
              href="https://docs.solace.com/SDKPerf/SDKPerf.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>{content.resources.sdkperf.title}</CardTitle>
                <CardText>{content.resources.sdkperf.description}</CardText>
              </Card>
            </a>
            <a
              href="https://solace.com/connectors/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>{content.resources.connector_hub.title}</CardTitle>
                <CardText>
                  {content.resources.connector_hub.description}
                </CardText>
              </Card>
            </a>
          </Col>
          <Col xs="12" md="4" lg="4">
            <a
              href="https://solace.community"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>
                  {content.resources.developer_community.title}
                </CardTitle>
                <CardText>
                  {content.resources.developer_community.description}
                </CardText>
              </Card>
            </a>
            <a
              href="https://solace.com/engage-developer-advocate/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card body inverse style={{ backgroundColor: "#343a40" }}>
                <CardTitle>{content.resources.ttyd.title}</CardTitle>
                <CardText>{content.resources.ttyd.description}</CardText>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Resources
