import React from "react"
import protocols from "./protocols.js"
import languages from "./languages.js"
import AccordionElement from "./accordion-element.js"
export default class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.toggleConnectDetailsFn = props.toggleConnectDetailsFn
    this.selectView = this.selectView.bind(this)
    this.state = {
      selectValue: "language",
      data: languages,
      // isExpandAllEnabled: false
    }
  }

  selectView = event => {
    let state = {}
    state.selectValue = event.currentTarget.value
    if (state.selectValue === "protocol") {
      state.data = protocols
    } else if (state.selectValue === "language") {
        state.data = languages
    }
    this.setState(state)
  }

  // expandAll = () => {
  //   this.state.isExpandAllEnabled = !this.state.isExpandAllEnabled;
  //   this.setState(this.state); //state => ({ isExpandAllEnabled: !state.isExpandAllEnabled})
  //   console.log(this.state.isExpandAllEnabled)
  // };

  render() {
    return (
      <div>
        <h3 className="title-bar">Connect Using a Supported Client Library</h3>

        <div
          className="sub-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Select a connection point and supported client library below to start
          messaging.
          <div className="filter">
            <span className="view-by">View by:</span>
            <img
              className="caret"
              src={require("./images/arrow_down.svg")}
              alt=""
            />
            <select aria-label="Select" onChange={this.selectView}>
              <option value="language">Language</option>
              <option value="protocol">Protocol</option>
            </select>
          </div>
        </div>
        <ul>
          {this.state.data.map(item => {
            return (
              <li key={item.displayName}>
                <AccordionElement
                  toggleConnectDetailsFn={this.toggleConnectDetailsFn}
                  /*expandedAll={this.state.isExpandAllEnabled}*/ selection={
                    this.state.selectValue
                  }
                  data={item}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
