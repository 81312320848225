/* eslint-disable */
export default {
  id: "solaceJavaRTOAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace JavaRTO",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Java",
    logo: "./images/java-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/javarto/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/jrto/index.html",
    download: [
      {
        name: "Linux 2.6 x86",
        group: "Linux",
        url: "https://products.solace.com/download/JAVA_RTO_API_LINUX32",
      },
      {
        name: "Linux 2.6 x64",
        group: "Linux",
        url: "https://products.solace.com/download/JAVA_RTO_API_LINUX64",
      },
      {
        name: "Windows x86",
        group: "Windows",
        url: "https://products.solace.com/download/JAVA_RTO_API_WIN32",
      },
      {
        name: "Windows x64",
        group: "Windows",
        url: "https://products.solace.com/download/JAVA_RTO_API_WIN64",
      },
      {
        name: "macOS",
        group: "Mac",
        url: "https://products.solace.com/download/JAVA_RTO_API_OSX",
      },
    ],
  },
  step1: {
    name: "JavaRTO API",
    getAPI: [
      {
        title: "Using Gradle",
        code: [
          'repositories {<br>\
                    &nbsp; flatDir(dir: "<PATH_TO_EXTRACTED_API>/solclientj/lib", name: "Java RTO API lib directory")<br>\
                }<br>\
                <br>\
                dependencies {<br>\
                    &nbsp; // Solace Messaging API for JavaRTO Dependencies<br>\
                    &nbsp; compile("com.solacesystems:solclientj:")<br>\
                }',
        ],
      },
      {
        title: "Using Maven",
        code: [
          "&lt;repositories&gt; <br>\
                &nbsp; &lt;repository&gt; <br>\
                  &nbsp; &nbsp; &lt;id&gt;localrepository&lt;/id&gt; <br>\
                 &nbsp; &nbsp;  &lt;url&gt;file://<PATH_TO_EXTRACTED_API>/solclientj/lib/&lt;/url&gt; <br>\
                &nbsp; &lt;/repository&gt; <br>\
              &lt;/repositories&gt; <br>\
              <br>\
              [...]<br>\
              <br>\
              &lt;dependency&gt;<br>\
                &nbsp&nbsp &lt;groupId&gt;com.solacesystems&lt/groupId&gt <br>\
                &nbsp&nbsp &lt;artifactId&gt;solclientj&lt;/artifactId&gt; <br>\
                &nbsp&nbsp &lt;version&gt;+&lt;/version&gt;<br>\
              &lt;/dependency&gt;",
        ],
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must connect a Solace session.",
      "The Solace session is the basis for all client communication with Solace messaging.",
    ],
    code: [
      {
        protocol: "SMF",
        sample:
          '\
                // Solclient needs to be initialized before any other API calls. <br>\
                Solclient.init(new String[0]); <br>\
                 <br>\
                // Context <br>\
                final ContextHandle contextHandle = Solclient.Allocator.newContextHandle();<br>\
                Solclient.createContextForHandle(contextHandle, new String[0]);<br>\
                <br>\
                // A message callback to receive messages asynchronously<br>\
                MessageCallback messageCallback = new MessageCallback() {<br>\
                    &nbsp;  @Override<br>\
                    &nbsp;  public void onMessage(Handle handle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received message:");<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                // A session event callback to events such as connect/disconnect events<br>\
                SessionEventCallback sessionEventCallback = new SessionEventCallback() {<br>\
                    &nbsp; @Override<br>\
                    &nbsp; public void onEvent(SessionHandle sessionHandle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received SessionEvent:" + sessionHandle.getSessionEvent());<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                // Configure the Session properties<br>\
                ArrayList<String> sessionProperties = new ArrayList<String>();<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.HOST);<br>\
                sessionProperties.add("<span class="value">${url}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.USERNAME);<br>\
                sessionProperties.add("<span class="value">${username}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.PASSWORD);<br>\
                sessionProperties.add("<span class="value">${password}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.VPN_NAME);<br>\
                sessionProperties.add("<span class="value">${vpn}</span>");<br>\
                String[] props = new String[sessionProperties.size()];<br>\
                <br>\
                // Instantiate a new SessionHandle instance<br>\
                final SessionHandle sessionHandle = Solclient.Allocator.newSessionHandle();<br>\
                <br>\
                // Create the Session<br>\
                contextHandle.createSessionForHandle(sessionHandle, sessionProperties.toArray(props), messageCallback, sessionEventCallback);<br>\
                <br>\
                // Connect the Session<br>\
                sessionHandle.connect();<br>\
                ',
      },
      {
        protocol: "Secured SMF",
        sample:
          '\
                // Solclient needs to be initialized before any other API calls. <br>\
                Solclient.init(new String[0]); <br>\
                 <br>\
                // Context <br>\
                final ContextHandle contextHandle = Solclient.Allocator.newContextHandle();<br>\
                Solclient.createContextForHandle(contextHandle, new String[0]);<br>\
                <br>\
                // A message callback to receive messages asynchronously<br>\
                MessageCallback messageCallback = new MessageCallback() {<br>\
                    &nbsp;  @Override<br>\
                    &nbsp;  public void onMessage(Handle handle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received message:");<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                // A session event callback to events such as connect/disconnect events<br>\
                SessionEventCallback sessionEventCallback = new SessionEventCallback() {<br>\
                    &nbsp; @Override<br>\
                    &nbsp; public void onEvent(SessionHandle sessionHandle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received SessionEvent:" + sessionHandle.getSessionEvent());<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                // Configure the Session properties<br>\
                ArrayList<String> sessionProperties = new ArrayList<String>();<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.HOST);<br>\
                sessionProperties.add("<span class="value">${url}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.USERNAME);<br>\
                sessionProperties.add("<span class="value">${username}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.PASSWORD);<br>\
                sessionProperties.add("<span class="value">${password}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.VPN_NAME);<br>\
                sessionProperties.add("<span class="value">${vpn}</span>");<br>\
                String[] props = new String[sessionProperties.size()];<br>\
                <br>\
                // Instantiate a new SessionHandle instance<br>\
                final SessionHandle sessionHandle = Solclient.Allocator.newSessionHandle();<br>\
                <br>\
                // Create the Session<br>\
                contextHandle.createSessionForHandle(sessionHandle, sessionProperties.toArray(props), messageCallback, sessionEventCallback);<br>\
                <br>\
                // Connect the Session<br>\
                sessionHandle.connect();<br>\
                ',
      },
      {
        protocol: "Compressed SMF",
        name: "Compressed SMF",
        sample:
          '\
                // Solclient needs to be initialized before any other API calls. <br>\
                Solclient.init(new String[0]); <br>\
                 <br>\
                // Context <br>\
                final ContextHandle contextHandle = Solclient.Allocator.newContextHandle();<br>\
                Solclient.createContextForHandle(contextHandle, new String[0]);<br>\
                <br>\
                // A message callback to receive messages asynchronously<br>\
                MessageCallback messageCallback = new MessageCallback() {<br>\
                    &nbsp;  @Override<br>\
                    &nbsp;  public void onMessage(Handle handle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received message:");<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                // A session event callback to events such as connect/disconnect events<br>\
                SessionEventCallback sessionEventCallback = new SessionEventCallback() {<br>\
                    &nbsp; @Override<br>\
                    &nbsp; public void onEvent(SessionHandle sessionHandle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received SessionEvent:" + sessionHandle.getSessionEvent());<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                // Configure the Session properties<br>\
                ArrayList<String> sessionProperties = new ArrayList<String>();<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.HOST);<br>\
                sessionProperties.add("<span class="value">${url}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.USERNAME);<br>\
                sessionProperties.add("<span class="value">${username}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.PASSWORD);<br>\
                sessionProperties.add("<span class="value">${password}</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.VPN_NAME);<br>\
                sessionProperties.add("<span class="value">${vpn}</span>");<br>\
                String[] props = new String[sessionProperties.size()];<br>\
                <br>\
                // Instantiate a new SessionHandle instance<br>\
                final SessionHandle sessionHandle = Solclient.Allocator.newSessionHandle();<br>\
                <br>\
                // Create the Session<br>\
                contextHandle.createSessionForHandle(sessionHandle, sessionProperties.toArray(props), messageCallback, sessionEventCallback);<br>\
                <br>\
                // Connect the Session<br>\
                sessionHandle.connect();<br>\
                ',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://tutorials.solace.dev/javarto/publish-subscribe/",
      },
    ],
  },
}
