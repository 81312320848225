import React from "react"
import Libraries from "./libraries.js"
export default class AccordianElement extends React.Component {
  constructor(props) {
    super(props)
    this.toggleConnectDetailsFn = props.toggleConnectDetailsFn
    this.expand = this.expand.bind(this)
    this.state = {
      expanded: false,
      selected: props.selection,
      data: props.data,
    }
    // if (props.expandAll) {
    //   this.state.expanded = true;
    // }
  }

  expand = () => {
    if (this.state.data.libraries.length !== 0) {
      this.setState(state => ({ expanded: !state.expanded }))
    }
  }

  accordianContent(toggleConnectDetailsFn, isExpanded, selection, data) {
    var content
    var rotateIconStyle = { transform: "none" }
    var collapsibleStyle = {}

    if (isExpanded) {
      rotateIconStyle = { transform: "rotate(180deg)" }
      // React native will complain that max-height should be maxHeight, but react's maxHeight is not compatible with css' transition property.
      collapsibleStyle = { maxHeight: "600px" } // Must be max-height to allow transitions
    } else {
      collapsibleStyle = { maxHeight: "0px" }
    }
    if (selection === "protocol") {
      content = (
        <div className="collapsible-content">
          <div className="client-libraries">
            <div className="title">Client Libraries</div>
            <div className="information-box">
              {
                <Libraries
                  toggleConnectDetailsFn={toggleConnectDetailsFn}
                  type={selection}
                  libraries={data.libraries}
                />
              }
            </div>
          </div>
        </div>
      )
    } else if (selection === "language" ) {
      content = (
        <div className="collapsible-content">
          <div className="client-libraries">
            {data.id === "spring" ? (
              <div className="library-title">Projects</div>
            ) : (
              <div className="library-title">Library</div>
            )}
            <div className="protocol-title">Protocol</div>
            <div className="information-box">
              {
                <Libraries
                  toggleConnectDetailsFn={toggleConnectDetailsFn}
                  type={selection}
                  libraries={data.libraries}
                />
              }
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="collapsible-accordion">
        <ul>
          <li>
            <div onClick={this.expand} className="collapsible-header">
              <div className="logo-box">
                <img
                  src={require("" + data.logo)}
                  style={{ maxHeight: `${data.logoHeight}px` }}
                  alt=""
                />
              </div>
              {this.state.data.libraries.length > 0 ? (
                <img
                  src={require("./images/arrow_down.svg")}
                  className="down-arrow"
                  style={rotateIconStyle}
                  alt=""
                />
              ) : (
                ""
              )}
              <div className="description">
                <div className="title">{data.displayName}</div>
                {data.description}
              </div>
            </div>
            <div className="collapsible-body" style={collapsibleStyle}>
              {content}
            </div>
          </li>
        </ul>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.accordianContent(
          this.toggleConnectDetailsFn,
          this.state.expanded,
          this.state.selected,
          this.state.data
        )}
      </div>
    )
  }
}
