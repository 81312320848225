/* eslint-disable */
export default {
  id: "springBootJavaAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Spring Boot Java API",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/spring-boot-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Spring",
    logo: "./images/spring-boot-logo.png",
  },
  links: {
    // tutorial: "https://github.com/SolaceProducts/solace-java-spring-boot#using-spring-dependency-auto-configuration-springbootapplication--autowired",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/java/index.html",
    download: [
      {
        name: "Solace Starter",
        url:
          "https://mvnrepository.com/artifact/com.solace.spring.boot/solace-java-spring-boot-starter",
      },
    ],
  },
  step1: {
    name: "Get the API",
    getAPI: [
      {
        title: "I already have a Maven Spring Boot Project",
        description:
          "Add the Solace Java Spring Boot Starter to your POM if using Maven.",
        code: [
          "&lt;dependency&gt; <br/>\
          &nbsp;&nbsp;&lt;groupId&gt;com.solace.spring.boot&lt;/groupId&gt; <br/>\
          &nbsp;&nbsp;&lt;artifactId&gt;solace-java-spring-boot-starter&lt;/artifactId&gt; <br/>\
          &nbsp;&nbsp;&lt;version&gt;3.1.0&lt;/version&gt; <br/>\
          &lt;/dependency&gt;",
        ],
      },
      {
        title: "I already have a Gradle Spring Boot Project",
        description:
          "Add the Solace Java Spring Boot Starter to your build.gradle if using Gradle.",
        code: [
          "compile group: 'com.solace.spring.boot', name: 'solace-java-spring-boot-starter', version: '3.1.0'",
        ],
      },
      {
        title:
          "Starting from Scratch - Use Spring Initializr to bootstrap my project!",
        description:
          'It is recommended to bootstrap your Spring Boot project using <a href="https://start.spring.io/" target="_blank">Spring Initializr</a>. Spring Initializr will \
          generate your Spring Boot project skeleton for you based on a few simple choices, such as your preferred language, Spring Boot version, and then dependencies you need to build your microservice. <br/><br/> \
          Once you bootstrap your Spring Boot project add the Solace Java Spring Boot Starter as shown in the previous section.',
      },
    ],
  },
  step2: {
    introduction: [
      'The Solace Java Spring Boot Starter that you added to your dependencies in the “Get API” \
        step auto-configures a SpringJCSMPFactory that you can inject into your own component. \
        This auto-configuration is enabled by the setting of application properties. \
        This is commonly done using the <strong>application.properties</strong> file as shown below. \
        Other external configuration options supported by Spring Boot are available <a href="https://docs.spring.io/spring-boot/docs/current/reference/html/boot-features-external-config.html" target="_blank">here</a>.',
    ],
    code: [
      {
        protocol: "Java API",
        sample: "",
      },
    ],
    conclusion: [
      "Once you have your SpringJCSMPFactory you can create your JCSMPSession(s) and you’re off to the races! \
       Learn more about the Solace Java API (JCSMP) here. See the “Learn More” tab for more information about the \
       Solace JMS Spring Boot Starter, including sample code.",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "demo-app",
        name: "Sample <br/>Application",
        logo: "./images/github-logo.png",
        url:
          "https://github.com/SolaceProducts/solace-spring-boot/blob/master/solace-spring-boot-samples/solace-java-sample-app/src/main/java/demo/DemoApplication.java",
      },
      {
        id: "tutorial",
        name: "Tutorial using <br/>Spring Boot Java <br/>API with PCF",
        logo: "./images/spring-cloud.svg",
        url: "https://tutorials.solace.dev/tanzu/spring-cloud-autoconf-java/",
      },
      {
        id: "blog",
        name: "Blog: Solace Java <br/>Meet Spring Boot <br/>Starters",
        logo: "./images/spring-boot.svg",
        url: "https://solace.com/blog/solace-java-meet-spring-boot-starters/",
      },
    ],
  },
}
