/* eslint-disable */
export default {
  id: "pahoJavaScriptAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Eclipse Paho JavaScript Client",
  protocol: {
    id: "mqtt",
    name: "MQTT",
    logo: "./images/paho.png",
    usesVPN: false,
  },
  language: {
    name: "JavaScript",
    logo: "./images/javascript.png",
  },
  links: {
    api: "http://www.eclipse.org/paho/files/jsdoc/index.html",
    download: [
      {
        name: "Eclipse Paho Portal",
        url: "https://www.eclipse.org/paho/clients/js/",
      },
    ],
  },
  step1: {
    name: "Eclipse Paho JavaScript Client",
    getAPI: [
      {
        title: "Download from Eclipse",
        description:
          'Get Eclipse Paho JavaScript Client from <a href="https://www.eclipse.org/paho/clients/js/" target="_bank">here</a>',
      },
    ],
  },
}
