/* eslint-disable */
export default {
  id: "solaceCAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace C",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "C",
    logo: "./images/c-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/c/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/c/index.html",
    download: [
      {
        name: "AIX 6 PowerPC",
        group: "PowerPC",
        url: "https://products.solace.com/download/C_API_AIX6_PPC",
      },
      {
        name: "Linux 2.6 x86",
        group: "Linux",
        url: "https://products.solace.com/download/C_API_LINUX32",
      },
      {
        name: "Linux 2.6 x64",
        group: "Linux",
        url: "https://products.solace.com/download/C_API_LINUX64",
      },
      {
        name: "Windows x86",
        group: "Windows",
        url: "https://solace.com/downloads/?fwp_downloads_types=messaging-apis-and-protocols",
      },
      {
        name: "Windows x64",
        group: "Windows",
        url: "https://solace.com/downloads/?fwp_downloads_types=messaging-apis-and-protocols",
      },
      {
        name: "VS2015",
        group: "Windows",
        url: "https://products.solace.com/download/C_API_VS2015",
      },
      {
        name: "macOS",
        group: "Mac",
        url: "https://products.solace.com/download/C_API_OSX",
      },
      {
        name: "Solaris x86",
        group: "Sun",
        url: "https://products.solace.com/download/C_API_SUNOS32",
      },
      {
        name: "Solaris x64",
        group: "Sun",
        url: "https://products.solace.com/download/C_API_SUNOS64",
      },
      {
        name: "SPARC 32",
        group: "Sun",
        url: "https://products.solace.com/download/C_API_SPARC32",
      },
      {
        name: "SPARC 64",
        group: "Sun",
        url: "https://products.solace.com/download/C_API_SPARC64",
      },
    ],
  },
  step1: {
    name: "C API",
    getAPI: [
      {
        title: "Using the Solace Developer Portal",
        description:
          'The C API library can be downloaded from the <a href="https://www.solace.com/downloads/" target="_blank">Solace Downloads</a>.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must connect a Solace session.",
      "The Solace session is the basis for all client communication with Solace messaging.",
    ],
    code: [
      {
        protocol: "SMF",
        sample:
          '\
                /* solClient needs to be initialized before any other API calls. */ <br>\
                solClient_initialize ( SOLCLIENT_LOG_DEFAULT_FILTER, NULL ); <br>\
                 <br>\
                // Context <br>\
                /* Context */ <br>\
                solClient_opaqueContext_pt context_p; <br>\
                solClient_context_createFuncInfo_t contextFuncInfo = SOLCLIENT_CONTEXT_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                solClient_context_create ( SOLCLIENT_CONTEXT_PROPS_DEFAULT_WITH_CREATE_THREAD, &context_p, &contextFuncInfo, sizeof ( contextFuncInfo ) );<br>\
                 <br>\
                // A message callback to receive messages asynchronously <br>\
                solClient_rxMsgCallback_returnCode_t messageReceiveCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_opaqueMsg_pt msg_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf ( "Received message:\\n" ); <br>\
                    &nbsp; solClient_msg_dump ( msg_p, NULL, 0 ); <br>\
                    &nbsp; printf ( "\\n" ); <br>\
                    &nbsp; return SOLCLIENT_CALLBACK_OK; <br>\
                } <br>\
                <br>\
                void eventCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_session_eventCallbackInfo_pt eventInfo_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf("Session EventCallback() called:  %s\\n", solClient_session_eventToString ( eventInfo_p->sessionEvent)); <br>\
                } <br>\
                 <br>\
                // Configure the Session properties <br>\
                /* Session */ <br>\
                solClient_opaqueSession_pt session_p; <br>\
                solClient_session_createFuncInfo_t sessionFuncInfo = SOLCLIENT_SESSION_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                /* Session Properties */ <br>\
                const char     *sessionProps[50]; <br>\
                int             propIndex = 0; <br>\
                <br>\
                /* Configure the Session function information. */ <br>\
                sessionFuncInfo.rxMsgInfo.callback_p = messageReceiveCallback; <br>\
                sessionFuncInfo.rxMsgInfo.user_p = NULL; <br>\
                sessionFuncInfo.eventInfo.callback_p = eventCallback; <br>\
                sessionFuncInfo.eventInfo.user_p = NULL; <br>\
                <br>\
                /* Configure the Session properties. */ <br>\
                propIndex = 0; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_HOST; <br>\
                sessionProps[propIndex++] = "<span class="value">${url}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_VPN_NAME; <br>\
                sessionProps[propIndex++] = "<span class="value">${vpn}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_USERNAME; <br>\
                sessionProps[propIndex++] = "<span class="value">${username}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_PASSWORD; <br>\
                sessionProps[propIndex] = "<span class="value">${password}</span>"; <br>\
                <br>\
                /* Create the Session. */ <br>\
                solClient_session_create ( ( char ** ) sessionProps, <br>\
                                        &nbsp; &nbsp; context_p, <br>\
                                        &nbsp; &nbsp; &session_p, &sessionFuncInfo, sizeof ( sessionFuncInfo ) ); <br>\
                <br>\
                /* Connect the Session. */ <br>\
                solClient_session_connect ( session_p ); <br>\
                ',
      },
      {
        protocol: "Secured SMF",
        sample:
          '\
                /* solClient needs to be initialized before any other API calls. */ <br>\
                solClient_initialize ( SOLCLIENT_LOG_DEFAULT_FILTER, NULL ); <br>\
                 <br>\
                // Context <br>\
                /* Context */ <br>\
                solClient_opaqueContext_pt context_p; <br>\
                solClient_context_createFuncInfo_t contextFuncInfo = SOLCLIENT_CONTEXT_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                solClient_context_create ( SOLCLIENT_CONTEXT_PROPS_DEFAULT_WITH_CREATE_THREAD, &context_p, &contextFuncInfo, sizeof ( contextFuncInfo ) ); <br>\
                 <br>\
                // A message callback to receive messages asynchronously <br>\
                solClient_rxMsgCallback_returnCode_t messageReceiveCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_opaqueMsg_pt msg_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf ( "Received message:\\n" ); <br>\
                    &nbsp; solClient_msg_dump ( msg_p, NULL, 0 ); <br>\
                    &nbsp; printf ( "\\n" ); <br>\
                    &nbsp; return SOLCLIENT_CALLBACK_OK; <br>\
                } <br>\
                <br>\
                void eventCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_session_eventCallbackInfo_pt eventInfo_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf("Session EventCallback() called:  %s\\n", solClient_session_eventToString ( eventInfo_p->sessionEvent)); <br>\
                } <br>\
                 <br>\
                // Configure the Session properties <br>\
                /* Session */ <br>\
                solClient_opaqueSession_pt session_p; <br>\
                solClient_session_createFuncInfo_t sessionFuncInfo = SOLCLIENT_SESSION_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                /* Session Properties */ <br>\
                const char     *sessionProps[50]; <br>\
                int             propIndex = 0; <br>\
                <br>\
                /* Configure the Session function information. */ <br>\
                sessionFuncInfo.rxMsgInfo.callback_p = messageReceiveCallback; <br>\
                sessionFuncInfo.rxMsgInfo.user_p = NULL; <br>\
                sessionFuncInfo.eventInfo.callback_p = eventCallback; <br>\
                sessionFuncInfo.eventInfo.user_p = NULL; <br>\
                <br>\
                /* Configure the Session properties. */ <br>\
                propIndex = 0; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_HOST; <br>\
                sessionProps[propIndex++] = "<span class="value">${url}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_VPN_NAME; <br>\
                sessionProps[propIndex++] = "<span class="value">${vpn}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_USERNAME; <br>\
                sessionProps[propIndex++] = "<span class="value">${username}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_PASSWORD; <br>\
                sessionProps[propIndex] = "<span class="value">${password}</span>"; <br>\
                <br>\
                /* Create the Session. */ <br>\
                solClient_session_create ( ( char ** ) sessionProps, <br>\
                                        &nbsp; &nbsp; context_p, <br>\
                                        &nbsp; &nbsp; &session_p, &sessionFuncInfo, sizeof ( sessionFuncInfo ) ); <br>\
                <br>\
                /* Connect the Session. */ <br>\
                solClient_session_connect ( session_p ); <br>\
                ',
      },
      {
        protocol: "Compressed SMF",
        name: "Compressed SMF",
        sample:
          '\
                /* solClient needs to be initialized before any other API calls. */ <br>\
                solClient_initialize ( SOLCLIENT_LOG_DEFAULT_FILTER, NULL ); <br>\
                 <br>\
                // Context <br>\
                /* Context */ <br>\
                solClient_opaqueContext_pt context_p; <br>\
                solClient_context_createFuncInfo_t contextFuncInfo = SOLCLIENT_CONTEXT_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                solClient_context_create ( SOLCLIENT_CONTEXT_PROPS_DEFAULT_WITH_CREATE_THREAD, &context_p, &contextFuncInfo, sizeof ( contextFuncInfo ) ); <br>\
                 <br>\
                // A message callback to receive messages asynchronously <br>\
                solClient_rxMsgCallback_returnCode_t messageReceiveCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_opaqueMsg_pt msg_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf ( "Received message:\\n" ); <br>\
                    &nbsp; solClient_msg_dump ( msg_p, NULL, 0 ); <br>\
                    &nbsp; printf ( "\\n" ); <br>\
                    &nbsp; return SOLCLIENT_CALLBACK_OK; <br>\
                } <br>\
                <br>\
                void eventCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_session_eventCallbackInfo_pt eventInfo_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf("Session EventCallback() called:  %s\\n", solClient_session_eventToString ( eventInfo_p->sessionEvent)); <br>\
                } <br>\
                 <br>\
                // Configure the Session properties <br>\
                /* Session */ <br>\
                solClient_opaqueSession_pt session_p; <br>\
                solClient_session_createFuncInfo_t sessionFuncInfo = SOLCLIENT_SESSION_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                /* Session Properties */ <br>\
                const char     *sessionProps[50]; <br>\
                int             propIndex = 0; <br>\
                <br>\
                /* Configure the Session function information. */ <br>\
                sessionFuncInfo.rxMsgInfo.callback_p = messageReceiveCallback; <br>\
                sessionFuncInfo.rxMsgInfo.user_p = NULL; <br>\
                sessionFuncInfo.eventInfo.callback_p = eventCallback; <br>\
                sessionFuncInfo.eventInfo.user_p = NULL; <br>\
                <br>\
                /* Configure the Session properties. */ <br>\
                propIndex = 0; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_HOST; <br>\
                sessionProps[propIndex++] = "<span class="value">${url}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_VPN_NAME; <br>\
                sessionProps[propIndex++] = "<span class="value">${vpn}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_USERNAME; <br>\
                sessionProps[propIndex++] = "<span class="value">${username}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_PASSWORD; <br>\
                sessionProps[propIndex++] = "<span class="value">${password}</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_COMPRESSION_LEVEL; <br>\
                sessionProps[propIndex++] = "9"; <br>\
                <br>\
                /* Create the Session. */ <br>\
                solClient_session_create ( ( char ** ) sessionProps, <br>\
                                        &nbsp; &nbsp; context_p, <br>\
                                        &nbsp; &nbsp; &session_p, &sessionFuncInfo, sizeof ( sessionFuncInfo ) ); <br>\
                <br>\
                /* Connect the Session. */ <br>\
                solClient_session_connect ( session_p ); <br>\
                ',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url: "https://tutorials.solace.dev/c/publish-subscribe/",
      },
    ],
  },
}
