import React from "react"
import ProtocolLibrary from "./protocol-library.js"
import LanguageLibrary from "./language-library.js"
import getClientLibrary from "./client-library-manager.js"
export default class Libraries extends React.Component {
  constructor(props) {
    super(props)
    this.toggleConnectDetailsFn = props.toggleConnectDetailsFn
    this.selectLibraryView = this.selectLibraryView.bind(this)
    this.libraries = props.libraries
    this.selection = props.type
  }

  selectLibraryView(selection, library) {
    if (selection === "protocol") {
      this.populateLibraryData(library)
      return (
        <li style={{ width: "100%" }}>
          <ProtocolLibrary data={library} />
        </li>
      )
    } else if (selection === "language") {
      return (
        <li style={{ width: "100%" }}>
          <LanguageLibrary data={library} />
        </li>
      )
    }
  }

  populateLibraryData(library) {
    // Something is fishy with this function. It appears to work, but it requires library.logo to be defined when we import the payload into this class for the require(imageURI) function.
    let librarySource = getClientLibrary(library.id)
    if (librarySource) {
      library.logo = librarySource.protocol.logo
      library.languages = librarySource.language.name
      library.displayName = librarySource.displayName
    }
  }

  render() {
    return (
      <div>
        <ul>
          {this.libraries.map(library => {
            return (
              <div
                className="library"
                key={library.id}
                onClick={() => this.toggleConnectDetailsFn(library.id)}
              >
                <div className="logo-box">
                  <img
                    className="logo"
                    src={require("" + library.logo)}
                    alt=""
                  ></img>
                </div>
                {this.selectLibraryView(
                  this.selection,
                  library,
                  this.toggleConnectDetailsFn
                )}
              </div>
            )
          })}
        </ul>
      </div>
    )
  }
}
