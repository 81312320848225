/* eslint-disable */
export default {
  id: "springBootJmsAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Spring Boot JMS API",
  protocol: {
    id: "jms",
    name: "JMS",
    logo: "./images/spring-boot-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Spring",
    logo: "./images/spring-boot-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/spring/spring-boot-autoconfigure",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/jms/index.html",
    download: [
      {
        name: "Solace Starter",
        url:
          "https://mvnrepository.com/artifact/com.solace.spring.boot/solace-jms-spring-boot-starter",
      },
    ],
  },
  step1: {
    name: "Get the API",
    getAPI: [
      {
        title: "I already have a Maven Spring Boot Project",
        description:
          "Add the Solace JMS Spring Boot Starter to your POM if using Maven.",
        code: [
          "&lt;dependency&gt <br/>\
          &nbsp;&nbsp;&lt;groupId&gtcom.solace.spring.boot&lt;/groupId&gt <br/>\
          &nbsp;&nbsp;&lt;artifactId&gtsolace-jms-spring-boot-starter&lt;/artifactId&gt <br/>\
          &nbsp;&nbsp;&lt;version&gt3.0.0&lt;/version&gt <br/>\
          &lt;/dependency&gt",
        ],
      },
      {
        title: "I already have a Gradle Spring Boot Project",
        description:
          "Add the Solace JMS Spring Boot Starter to your build.gradle if using Gradle.",
        code: [
          "compile group: 'com.solace.spring.boot', name: 'solace-jms-spring-boot-starter', version: '3.0.0'",
        ],
      },
      {
        title:
          "Starting from Scratch - Use Spring Initializr to bootstrap my project!",
        description:
          'It is recommended to bootstrap your Spring Boot project using <a href="https://start.spring.io/#!dependencies=solace" target="_blank">Spring Initializr</a>.  \
          <br/> Spring Initializr will generate your Spring Boot project skeleton for you based on a few simple choices, \
          such as your preferred language, <br/> Spring Boot version, and then dependencies you need to build your microservice.<br/><br/> \
          Make sure to choose the Solace dependency!',
      },
    ],
  },
  step2: {
    introduction: [
      'The Solace JMS Spring Boot Starter that you added to your dependencies in the "Get API" step \
       enables autoconfiguration of a ConnectionFactory or JndiTemplate.<br/> \
       This allows developers to use the power of Spring to easily create JMS applications with PubSub+. \
       More info on how this auto-configuration works can be found <a href="https://solace.com/blog/solace-jms-meet-spring-boot-starters/" target="_blank">here</a>. <br/> \
       To take advantage of this auto-configuration you just need to set some application properties. \
       An example below shows how to do so using the <strong>application.properties</strong> file.<br/> \
       Other external configuration options supported by Spring Boot are available <a href="https://docs.spring.io/spring-boot/docs/current/reference/html/boot-features-external-config.html" target="_blank">here</a>.',
    ],
    code: [
      {
        protocol: "JMS",
        sample: "",
      },
    ],
    conclusion: [
      "See the “Learn More” tab for more information about the Solace JMS Spring Boot Starter, including sample code.",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "tutorial",
        name: "JMS Tutorial",
        logo: "./images/spring-boot.svg",
        url: "https://tutorials.solace.dev/spring/spring-boot-autoconfigure/ ",
      },
      {
        id: "blog",
        name: "Blog: Solace JMS <br/>Meet Spring Boot <br/>Starters",
        logo: "./images/spring-boot.svg",
        url: "https://solace.com/blog/solace-jms-meet-spring-boot-starters/",
      },
      {
        id: "jndi-tutorial",
        name: "JMS Tutorial <br/>Using JNDI & PCF",
        logo: "./images/github-logo.png",
        url: "https://tutorials.solace.dev/tanzu/spring-cloud-autoconf-jndi/",
      },
    ],
  },
}
