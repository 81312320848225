/* eslint-disable */
const pubCode = [
  {
    name: "Java",
    id: "java-pub",
    code: 'MessagingService messagingService = MessagingService.builder(ConfigurationProfile.V1)<br>\
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.fromProperties(properties).build().connect();<br>\
    DirectMessagePublisher publisher = messagingService.createDirectMessagePublisherBuilder()<br>\
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.onBackPressureWait(1).build().start();<br>\
    OutboundMessage message = messagingService.messageBuilder().build("Hello World!");<br>\
    Topic topic = Topic.of("solace/try/this/topic");<br>\
    publisher.publish(message, topic);'
  },
  {
    name: "Python",
    id: "python-pub",
    code: 'topic = Topic.of("solace/try/this/topic") <br>\
    messaging_service = MessagingService.builder().from_properties(broker_props).build().connect() <br>\
    direct_publisher = messaging_service.create_direct_message_publisher_builder().build().start() <br>\
    outbound_msg = messaging_service.message_builder().build("Hello world!") <br>\
    direct_publisher.publish(destination=topic, message=outbound_msg)'
  },
  {
    name: "Go",
    id: "go-pub",
    code: 'topic := resource.TopicOf("solace/try/this/topic") <br>\
    messagingService, err := messaging.NewMessagingServiceBuilder().FromConfigurationProvider(brokerConfig).Build() <br>\
    directPublisher, builderErr := messagingService.CreateDirectMessagePublisherBuilder().Build() <br>\
    outbound_msg, err := messagingService.MessageBuilder().BuildWithStringPayload("Hello world") <br>\
    publishErr := directPublisher.Publish(outbound_msg, topic)'
  },
  {
    name: "Spring",
    id: "scst-pub",
    code: '// Publish using Spring Cloud Stream <br>\
      @Bean <br>\
      public Supplier<String> helloWorldSend(){ <br>\
        &nbsp; return () -> { <br>\
          &nbsp;&nbsp; return "Hello World"; <br>\
          &nbsp; }; <br>\
      }'
  }, 
  {
    name: "JS",
    id: "js-pub",
    code: 'var message = solace.SolclientFactory.createMessage();<br>\
      message.setDestination(solace.SolclientFactory.createTopicDestination("solace/try/this/topic"));<br>\
      message.setBinaryAttachment("Sample Message");<br>\
      message.setDeliveryMode(solace.MessageDeliveryModeType.DIRECT);<br>\
      publisher.session.send(message);'
  },
  {
    name: "REST",
    id: "rest-pub",
    code: 'curl -X POST http://[HOST]:[PORT]/solace/try/this/topic \\<br>\
    -d "Hello World REST" \\<br>\
    -H "Content-Type: text/plain" <br>\
    --user [client-username]:[password]'
  },
  
   {
    name: "MQTT",
    id: "mqtt-pub",
    code: 'String content = "Hello world from MQTT!"<br>\
    MqttMessage message = new MqttMessage(content.getBytes())<br>\
    message.setQos(0)<br>\
    String topic = "solace/try/this/topic"<br>\
    mqttClient.publish(topic, message)'
  }, {
    name: "JMS 2.0",
    id: "jms2.0-pub",
    code: 'JMSContext context = connectionFactory.createContext();<br>\
    Topic topic = context.createTopic("solace/try/this/topic");<br>\
    TextMessage message = context.createTextMessage("Hello world!");<br>\
    JMSProducer producer = context.createProducer().setDeliveryMode(DeliveryMode.NON_PERSISTENT);<br>\
    producer.send(topic, message);'
  }, {
    name: "JMS 1.1",
    id: "jms1.1-pub",
    code: 'Topic topic = session.createTopic("solace/try/this/topic");<br>\
    MessageProducer messageProducer = session.createProducer(topic);<br>\
    TextMessage message = session.createTextMessage("Hello world!");<br>\
    messageProducer.send(topic, message, DeliveryMode.NON_PERSISTENT,<br>\
        Message.DEFAULT_PRIORITY, Message.DEFAULT_TIME_TO_LIVE);'
  },
]

export default pubCode
