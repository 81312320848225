import React from "react"
import { Container, Row, Col } from "reactstrap"
import content from "../data/content"
import Tile1 from "../images/ep-tile-1.jpg"
import Tile2 from "../images/ep-tile-2.jpg"
import Tile3 from "../images/ep-tile-3.jpg"
import Tile4 from "../images/ep-tile-4.jpg"

const EventPortal = props => {
  return (
    <section className="LightGrey" id="EventPortal">
      <Container fluid={true}>
        <h2 className="mb-5">Solace Event Portal</h2>
        <Row>
          <Col xs="12" md="8" lg="8">
            <p>
              Solace Event Portal is the leading solution for organizations
              looking to maximize the value of their Event-Driven Architecture
              by enhancing the ability to optimize event flows, improve
              collaboration between developers and architects, and more!
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="3" lg="3">
            <img
              src={Tile1}
              className="screenshot"
              alt="Design and Visualize event-driven systems"
            />
            <h3 className="mt-4">Design & Visualize your EDA</h3>
            <p className="mt-4">
              Visualize event flows between application domains, applications,
              and payload schema to event relationships.
            </p>
          </Col>
          <Col xs="12" md="3" lg="3">
            <img
              src={Tile2}
              className="screenshot"
              alt="Embrace Event API Products"
            />
            <h3 className="mt-4">Embrace Event APIs</h3>
            <p className="mt-4">
              Curate and share sets of related high-value business events with
              internal and external developers to enrich customer experiences.
            </p>
          </Col>
          <Col xs="12" md="3" lg="3">
            <img
              src={Tile3}
              className="screenshot"
              alt="Catalog Your Event Data"
            />
            <h3 className="mt-4">Catalog Your Event Data</h3>
            <p className="mt-4">
              Document and track existing topics, schemas, event meshes, pub/sub
              interfaces for each application, owners and points of contact, and
              changes for each of the managed EDA entities.
            </p>
          </Col>
          <Col xs="12" md="3" lg="3">
            <img src={Tile4} className="screenshot" alt="Operate your EDA" />
            <h3 className="mt-4">Operate your EDA</h3>
            <p className="mt-4">
              Create and track versions of EDA objects, promote them through
              environments and analyze dependencies to assess impact
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4" lg="4" className="text-center">
            <a
              className="button"
              href="https://docs.solace.com/Cloud/Event-Portal/event-portal-overview.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read the Documentation
            </a>
          </Col>
          <Col xs="12" md="4" lg="4" className="text-center">
            <a
              className="button"
              href="http://www.solace.com/products/portal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about Event Portal
            </a>
          </Col>
          <Col xs="12" md="4" lg="4" className="text-center">
            <a
              className="button"
              href="https://solace.com/blog/software-development-lifecycle-with-event-portal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Integrate Event Portal w/ SDLC
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EventPortal
