import React from "react"

export default class DetailsArea extends React.Component {
  constructor(props) {
    super(props)
    this.selectedLibrary = props.selectedLibrary
    this.state = {
      step: props.step,
      protocol: this.selectedLibrary.protocol.name,
      copied: false,
      username: "default",
      password: "default",
      url: "localhost",
      vpn: "default",
    }
    this.selectSampleCode = this.selectSampleCode.bind(this)
  }

  componentWillReceiveProps(props) {
    if (props.step) {
      this.setState(state => ({ step: props.step }))
    }
    if (props.username) {
      this.setState(state => ({ username: props.username }))
    }
    if (props.password) {
      this.setState(state => ({ password: props.password }))
    }
    if (props.url) {
      this.setState(state => ({ url: props.url }))
    }
    if (props.vpn) {
      this.setState(state => ({ vpn: props.vpn }))
    }
  }

  createMarkup(dangerousHTML) {
    return { __html: dangerousHTML }
  }
  createCodeMarkup() {
    switch (this.selectedLibrary.id) {
      case "springBootJmsAPI":
        return this.createMarkup(
          `
            solace.jms.host=<span style="color: #00AD93;">${
              this.state.url === "localhost"
                ? "tcp://" + this.state.url + ":55555"
                : this.state.url
            }</span> <br>\
            solace.jms.msg-vpn=<span style="color: #00AD93;">${
              this.state.vpn
            }</span> <br>\
            solace.jms.client-username=<span style="color: #00AD93;">${
              this.state.username
            }</span> <br>\
            solace.jms.client-password=<span style="color: #00AD93;">${
              this.state.password
            }</span>
          `
        )
      case "springBootJavaAPI":
        return this.createMarkup(
          `
            solace.java.host=<span style="color: #00AD93;">${
              this.state.url === "localhost"
                ? "tcp://" + this.state.url + ":55555"
                : this.state.url
            }</span> <br>\
            <span style="color: #508A4D;">## Optional options below:</span>  <br>\
            solace.java.msg-vpn=<span style="color: #00AD93;">${
              this.state.vpn
            }</span> <br>\
            solace.java.client-username=<span style="color: #00AD93;">${
              this.state.username
            }</span><br>\
            solace.java.client-password=<span style="color: #00AD93;">${
              this.state.password
            }</span><br>\
            <span style="color: #508A4D;">#solace.java.connect-retries=1 <br>\
            #solace.java.reconnect-retries=5 <br>\
            #solace.java.connect-retries-per-host=20  <br>\
            #solace.java.reconnect-retry-wait-in-millis=3000 <br>\
            # Optional Solace Java API properties are also available. <br>\
            # The name of the property can be obtained from: <br>\
            # https://docs.solace.com/API-Developer-Online-Ref-Documentation/java/constant-values.html </span><br>\
            solace.java.apiProperties.reapply_subscriptions=true <br>\
            <span style="color: #508A4D;">#solace.java.apiProperties.ssl_trust_store=/path/to/truststore</span>
          `
        )
      case "springCloudStreams":
        return this.createMarkup(
          `<pre style="white-space: pre;
          background-color: inherit !important;
          color: inherit;
          border: inherit !important;
          font-size: inherit;">
spring:  <br>\
  cloud: <br>\
    function: <br>\
      definition: myFunction
    stream:  <br>\
      binders:  <br>\
      <span style="color: #508A4D;">#This section of the configuration tells the solace binder how to connect to the solace event broker/mesh </span><br>\
        local-solace:  <br>\
          type: solace <br>\
          environment: <br>\
            solace: <br>\
              java: <br>\
                host: <span style="color: #00AD93;">${
                  this.state.url === "localhost"
                    ? "tcp://" + this.state.url + ":55555"
                    : this.state.url
                }</span> <br>\
                msgVpn: <span style="color: #00AD93;">${
                  this.state.vpn
                }</span> <br>\
                clientUsername: <span style="color: #00AD93;">${
                  this.state.username
                }</span> <br>\
                clientPassword: <span style="color: #00AD93;">${
                  this.state.password
                }</span> <br>\
                connectRetries: 3 <br>\
                connectRetriesPerHost: 0 <br>\
                reconnectRetries: 3 <br>\
      bindings:  <br>\
      <span style="color: #508A4D;">#The bindings section is used to define your input and output channels. </span><br>\
        myFunction-in-0: <br>\
          destination: QUEUE.NAME <br>\
          <span style="color: #508A4D;">#The presence of "group" tells the binder to follow the "consumer group" pattern; if not present the "publish-subscribe" pattern will be used. </span> <br>\
          group: GROUP <br>\
        myFunction-out-0: <br>\
          destination: send/to/topic <br>\
      solace: <br>\
      <span style="color: #508A4D;">#The solace bindings section allows for solace specific configurations to be applied to a channel. \
A common example is adding topic subscriptions to a queue as shown below. </span><br>\
        bindings: <br>\
          myFunction-in-0: <br>\
            consumer: <br>\
              queueAdditionalSubscriptions: topic/subscriptions,wildcards/*/accepted/></pre>`
        )
      case "solaceQpid1API":
        switch (this.state.protocol) {
          case "AMQP":
            return this.createMarkup(
              `ConnectionFactory connectionFactory = new JmsConnectionFactory("<span style="color: #00AD93;">${
                this.state.username
              }</span>", "<span style="color: #00AD93;">${
                this.state.password
              }</span>", "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "amqp://" + this.state.url + ":5672"
                  : this.state.url
              }</span>"); <br>\
                Connection connection = connectionFactory.createConnection(); <br>\
                Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE); <br>`
            )
          case "Secured AMQP":
            return this.createMarkup(
              `ConnectionFactory connectionFactory = new JmsConnectionFactory("<span style="color: #00AD93;">${
                this.state.username
              }</span>", "<span style="color: #00AD93;">${
                this.state.password
              }</span>", "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "amqps://" + this.state.url + ":5671"
                  : this.state.url
              }</span>"); <br>\
                Connection connection = connectionFactory.createConnection(); <br>\
                Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE); <br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "pahoJavaAPI":
        switch (this.state.protocol) {
          case "MQTT":
            return this.createMarkup(
              `MqttClient mqttClient = new MqttClient("<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "tcp://" + this.state.url + ":1883"
                  : this.state.url
              }</span>", "HelloWorldSub"); <br>\
                MqttConnectOptions connectionOptions = new MqttConnectOptions(); <br>\
                connectionOptions.setUsername("<span style="color: #00AD93;">${
                  this.state.username
                }</span>"); <br>\
                connectionOptions.setPassword("<span style="color: #00AD93;">${
                  this.state.password
                }</span>".toCharArray()) <br>\
                mqttClient.connect(connectionOptions);`
            )
          case "Secured MQTT":
            return this.createMarkup(
              `MqttClient mqttClient = new MqttClient("<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "ssl://" + this.state.url + ":8883"
                  : this.state.url
              }</span>", "HelloWorldSub"); <br>\
                MqttConnectOptions connectionOptions = new MqttConnectOptions(); <br>\
                connectionOptions.setUsername("<span style="color: #00AD93;">${
                  this.state.username
                }</span>"); <br>\
                connectionOptions.setPassword("<span style="color: #00AD93;">${
                  this.state.password
                }</span>".toCharArray()) <br>\
                mqttClient.connect(connectionOptions);`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solaceRest":
        switch (this.state.protocol) {
          case "REST":
            return this.createMarkup(
              `curl -X POST <span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "http://" + this.state.url + ":9000"
                  : this.state.url
              }</span>/T/rest/pubsub \\<br>\
                -d "Hello World REST" \\<br>\
                -H "content-type: text" \\<br>\
                -H "Solace-delivery-mode: direct" \\<br>\
                --user <span style="color: #00AD93;">${
                  this.state.username
                }</span>:<span style="color: #00AD93;">${
                this.state.password
              }</span>`
            )
          case "Secured REST":
            return this.createMarkup(
              `curl -X POST <span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "https://" + this.state.url + ":9443"
                  : this.state.url
              }</span>/T/rest/pubsub \\<br>\
                -d "Hello World REST" \\<br>\
                -H "content-type: text" \\<br>\
                -H "Solace-delivery-mode: direct" \\<br>\
                --user <span style="color: #00AD93;">${
                  this.state.username
                }</span>:<span style="color: #00AD93;">${
                this.state.password
              }</span>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }

      case "solace-amqp10-api":
        switch (this.state.protocol) {
          case "AMQP":
            return this.createMarkup(
              `var AMQP = require("amqp10"); <br>\
                <br>\
                var amqpClient = new AMQP.Client(AMQP.Policy.merge({ <br>\
                &nbsp; defaultSubjects : false <br>\
                })); <br>\
                <br>\
                amqpClient.connect("amqp://<span style="color: #00AD93;">${
                  this.state.username
                }</span>:<span style="color: #00AD93;">${
                this.state.password
              }</span>@<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "amqp://" + this.state.url + ":5672"
                  : this.state.url
              }</span>").then(() => { <br>\
                    &nbsp; console.log("Connected") <br>\
                }); <br>`
            )
          case "Secured AMQP":
            return this.createMarkup(
              `var AMQP = require("amqp10"); <br>\
                <br>\
                var amqpClient = new AMQP.Client(AMQP.Policy.merge({ <br>\
                &nbsp; defaultSubjects : false <br>\
                })); <br>\
                <br>\
                amqpClient.connect("amqp://<span style="color: #00AD93;">${
                  this.state.username
                }</span>:<span style="color: #00AD93;">${
                this.state.password
              }</span>@<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "amqps://" + this.state.url + ":5671"
                  : this.state.url
              }</span>").then(() => { <br>\
                    &nbsp; console.log("Connected") <br>\
                }); <br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solaceCAPI":
        switch (this.state.protocol) {
          case "SMF":
            return this.createMarkup(
              `<span style="color: #508A4D;">/* solClient needs to be initialized before any other API calls. */</span> <br>\
                  solClient_initialize ( SOLCLIENT_LOG_DEFAULT_FILTER, NULL ); <br>\
                      <br>\
                      <span style="color: #508A4D;">/* Context */</span> <br>\
                  solClient_opaqueContext_pt context_p; <br>\
                  solClient_context_createFuncInfo_t contextFuncInfo = SOLCLIENT_CONTEXT_CREATEFUNC_INITIALIZER; <br>\
                  <br>\
                  solClient_context_create ( SOLCLIENT_CONTEXT_PROPS_DEFAULT_WITH_CREATE_THREAD, &context_p, &contextFuncInfo, sizeof ( contextFuncInfo ) );<br>\
                      <br>\
                      <span style="color: #508A4D;">// A message callback to receive messages asynchronously </span><br>\
                  solClient_rxMsgCallback_returnCode_t messageReceiveCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_opaqueMsg_pt msg_p, void *user_p ) <br>\
                  { <br>\
                      &nbsp; printf ( "Received message:\\n" ); <br>\
                      &nbsp; solClient_msg_dump ( msg_p, NULL, 0 ); <br>\
                      &nbsp; printf ( "\\n" ); <br>\
                      &nbsp; return SOLCLIENT_CALLBACK_OK; <br>\
                  } <br>\
                  <br>\
                  void eventCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_session_eventCallbackInfo_pt eventInfo_p, void *user_p ) <br>\
                  { <br>\
                      &nbsp; printf("Session EventCallback() called:  %s\\n", solClient_session_eventToString ( eventInfo_p->sessionEvent)); <br>\
                  } <br>\
                      <br>\
                  <span style="color: #508A4D;">// Configure the Session properties <br>\
                  /* Session */ </span><br>\
                  solClient_opaqueSession_pt session_p; <br>\
                  solClient_session_createFuncInfo_t sessionFuncInfo = SOLCLIENT_SESSION_CREATEFUNC_INITIALIZER; <br>\
                  <br>\
                  <span style="color: #508A4D;">/* Session Properties */ </span><br>\
                  const char     *sessionProps[50]; <br>\
                  int             propIndex = 0; <br>\
                  <br>\
                  <span style="color: #508A4D;">/* Configure the Session function information.</span> */ <br>\
                  sessionFuncInfo.rxMsgInfo.callback_p = messageReceiveCallback; <br>\
                  sessionFuncInfo.rxMsgInfo.user_p = NULL; <br>\
                  sessionFuncInfo.eventInfo.callback_p = eventCallback; <br>\
                  sessionFuncInfo.eventInfo.user_p = NULL; <br>\
                  <br>\
                  <span style="color: #508A4D;">/* Configure the Session properties. */</span> <br>\
                  propIndex = 0; <br>\
                  <br>\
                  sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_HOST; <br>\
                  sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                    this.state.url === "localhost"
                      ? "tcp://" + this.state.url + ":55555"
                      : this.state.url
                  }</span>"; <br>\
                  <br>\
                  sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_VPN_NAME; <br>\
                  sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                    this.state.vpn
                  }</span>"; <br>\
                  <br>\
                  sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_USERNAME; <br>\
                  sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                    this.state.username
                  }</span>"; <br>\
                  <br>\
                  sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_PASSWORD; <br>\
                  sessionProps[propIndex] = "<span style="color: #00AD93;">${
                    this.state.password
                  }</span>"; <br>\
                  <br>\
                  <span style="color: #508A4D;">/* Create the Session. */</span> <br>\
                  solClient_session_create ( ( char ** ) sessionProps, <br>\
                                          &nbsp; &nbsp; context_p, <br>\
                                          &nbsp; &nbsp; &session_p, &sessionFuncInfo, sizeof ( sessionFuncInfo ) ); <br>\
                  <br>\
                  <span style="color: #508A4D;">/* Connect the Session. */ </span><br>\
                  solClient_session_connect ( session_p ); <br>`
            )
          case "Secured SMF":
            return this.createMarkup(
              `<span style="color: #508A4D;">/* solClient needs to be initialized before any other API calls. */</span> <br>\
                solClient_initialize ( SOLCLIENT_LOG_DEFAULT_FILTER, NULL ); <br>\
                    <br>\
                    <span style="color: #508A4D;">/* Context */</span> <br>\
                solClient_opaqueContext_pt context_p; <br>\
                solClient_context_createFuncInfo_t contextFuncInfo = SOLCLIENT_CONTEXT_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                solClient_context_create ( SOLCLIENT_CONTEXT_PROPS_DEFAULT_WITH_CREATE_THREAD, &context_p, &contextFuncInfo, sizeof ( contextFuncInfo ) );<br>\
                    <br>\
                    <span style="color: #508A4D;">// A message callback to receive messages asynchronously </span><br>\
                solClient_rxMsgCallback_returnCode_t messageReceiveCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_opaqueMsg_pt msg_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf ( "Received message:\\n" ); <br>\
                    &nbsp; solClient_msg_dump ( msg_p, NULL, 0 ); <br>\
                    &nbsp; printf ( "\\n" ); <br>\
                    &nbsp; return SOLCLIENT_CALLBACK_OK; <br>\
                } <br>\
                <br>\
                void eventCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_session_eventCallbackInfo_pt eventInfo_p, void *user_p ) <br>\
                { <br>\
                    &nbsp; printf("Session EventCallback() called:  %s\\n", solClient_session_eventToString ( eventInfo_p->sessionEvent)); <br>\
                } <br>\
                    <br>\
                <span style="color: #508A4D;">// Configure the Session properties <br>\
                /* Session */ </span><br>\
                solClient_opaqueSession_pt session_p; <br>\
                solClient_session_createFuncInfo_t sessionFuncInfo = SOLCLIENT_SESSION_CREATEFUNC_INITIALIZER; <br>\
                <br>\
                <span style="color: #508A4D;">/* Session Properties */ </span><br>\
                const char     *sessionProps[50]; <br>\
                int             propIndex = 0; <br>\
                <br>\
                <span style="color: #508A4D;">/* Configure the Session function information.</span> */ <br>\
                sessionFuncInfo.rxMsgInfo.callback_p = messageReceiveCallback; <br>\
                sessionFuncInfo.rxMsgInfo.user_p = NULL; <br>\
                sessionFuncInfo.eventInfo.callback_p = eventCallback; <br>\
                sessionFuncInfo.eventInfo.user_p = NULL; <br>\
                <br>\
                <span style="color: #508A4D;">/* Configure the Session properties. */</span> <br>\
                propIndex = 0; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_HOST; <br>\
                sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                  this.state.url === "localhost"
                    ? "tcps://" + this.state.url + ":55443"
                    : this.state.url
                }</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_VPN_NAME; <br>\
                sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                  this.state.vpn
                }</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_USERNAME; <br>\
                sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                  this.state.username
                }</span>"; <br>\
                <br>\
                sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_PASSWORD; <br>\
                sessionProps[propIndex] = "<span style="color: #00AD93;">${
                  this.state.password
                }</span>"; <br>\
                <br>\
                <span style="color: #508A4D;">/* Create the Session. */</span> <br>\
                solClient_session_create ( ( char ** ) sessionProps, <br>\
                                        &nbsp; &nbsp; context_p, <br>\
                                        &nbsp; &nbsp; &session_p, &sessionFuncInfo, sizeof ( sessionFuncInfo ) ); <br>\
                <br>\
                <span style="color: #508A4D;">/* Connect the Session. */ </span><br>\
                solClient_session_connect ( session_p ); <br>`
            )
          case "Compressed SMF":
            return this.createMarkup(
              `<span style="color: #508A4D;">/* solClient needs to be initialized before any other API calls. */ </span><br>\
              solClient_initialize ( SOLCLIENT_LOG_DEFAULT_FILTER, NULL ); <br>\
              <br>\
              <span style="color: #508A4D;">/* Context */</span> <br>\
              solClient_opaqueContext_pt context_p; <br>\
              solClient_context_createFuncInfo_t contextFuncInfo = SOLCLIENT_CONTEXT_CREATEFUNC_INITIALIZER; <br>\
              <br>\
              solClient_context_create ( SOLCLIENT_CONTEXT_PROPS_DEFAULT_WITH_CREATE_THREAD, &context_p, &contextFuncInfo, sizeof ( contextFuncInfo ) ); <br>\
              <br>\
              <span style="color: #508A4D;">// A message callback to receive messages asynchronously </span> <br>\
              solClient_rxMsgCallback_returnCode_t messageReceiveCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_opaqueMsg_pt msg_p, void *user_p ) <br>\
              { <br>\
                  &nbsp; printf ( "Received message:\\n" ); <br>\
                  &nbsp; solClient_msg_dump ( msg_p, NULL, 0 ); <br>\
                  &nbsp; printf ( "\\n" ); <br>\
                  &nbsp; return SOLCLIENT_CALLBACK_OK; <br>\
              } <br>\
              <br>\
              void eventCallback ( solClient_opaqueSession_pt opaqueSession_p, solClient_session_eventCallbackInfo_pt eventInfo_p, void *user_p ) <br>\
              { <br>\
                  &nbsp; printf("Session EventCallback() called:  %s\\n", solClient_session_eventToString ( eventInfo_p->sessionEvent)); <br>\
              } <br>\
              <br>\
              <span style="color: #508A4D;">// Configure the Session properties <br>\
              /* Session */ </span> <br>\
              solClient_opaqueSession_pt session_p; <br>\
              solClient_session_createFuncInfo_t sessionFuncInfo = SOLCLIENT_SESSION_CREATEFUNC_INITIALIZER; <br>\
              <br>\
              <span style="color: #508A4D;">/* Session Properties */ </span><br>\
              const char     *sessionProps[50]; <br>\
              int             propIndex = 0; <br>\
              <br>\
              <span style="color: #508A4D;">/* Configure the Session function information. */ </span> <br>\
              sessionFuncInfo.rxMsgInfo.callback_p = messageReceiveCallback; <br>\
              sessionFuncInfo.rxMsgInfo.user_p = NULL; <br>\
              sessionFuncInfo.eventInfo.callback_p = eventCallback; <br>\
              sessionFuncInfo.eventInfo.user_p = NULL; <br>\
              <br>\
              <span style="color: #508A4D;">/* Configure the Session properties. */ </span> <br>\
              propIndex = 0; <br>\
              <br>\
              sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_HOST; <br>\
              sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "tcp://" + this.state.url + ":55003"
                  : this.state.url
              }</span>"; <br>\
              <br>\
              sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_VPN_NAME; <br>\
              sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                this.state.vpn
              }</span>"; <br>\
              <br>\
              sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_USERNAME; <br>\
              sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                this.state.username
              }</span>"; <br>\
              <br>\
              sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_PASSWORD; <br>\
              sessionProps[propIndex++] = "<span style="color: #00AD93;">${
                this.state.password
              }</span>"; <br>\
              <br>\
              sessionProps[propIndex++] = SOLCLIENT_SESSION_PROP_COMPRESSION_LEVEL; <br>\
              sessionProps[propIndex++] = "9"; <br>\
              <br>\
              <span style="color: #508A4D;">/* Create the Session. */ </span> <br>\
              solClient_session_create ( ( char ** ) sessionProps, <br>\
                                      &nbsp; &nbsp; context_p, <br>\
                                      &nbsp; &nbsp; &session_p, &sessionFuncInfo, sizeof ( sessionFuncInfo ) ); <br>\
              <br>\
              <span style="color: #508A4D;">/* Connect the Session. */ </span> <br>\
              solClient_session_connect ( session_p ); <br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solaceDotNetAPI":
        switch (this.state.protocol) {
          case "SMF":
            return this.createMarkup(
              `ContextFactoryProperties cfp = new ContextFactoryProperties() <br>\
                { <br>\
                SolClientLogLevel = SolLogLevel.Warning <br>\
                }; <br>\
                cfp.LogToConsoleError(); <br>\
                ContextFactory.Instance.Init(cfp); <br>\
                <br>\
                SessionProperties sessionProps = new SessionProperties() <br>\
                { <br>\
                    &nbsp; Host = "<span style="color: #00AD93;">${
                      this.state.url === "localhost"
                        ? "tcp://" + this.state.url + ":55555"
                        : this.state.url
                    }</span>", <br>\
                    &nbsp; VPNName = "<span style="color: #00AD93;">${
                      this.state.vpn
                    }</span>", <br>\
                    &nbsp; UserName = "<span style="color: #00AD93;">${
                      this.state.username
                    }</span>", <br>\
                    &nbsp; Password = "<span style="color: #00AD93;">${
                      this.state.password
                    }</span>", <br>\
                    &nbsp; ReconnectRetries = DefaultReconnectRetries <br>\
                }; <br>\
                <br>\
                using (IContext context = ContextFactory.Instance.CreateContext(new ContextProperties(), null)) <br>\
                using (ISession session = context.CreateSession(sessionProps, HandleMessage, null)) <br>\
                { <br>\
                    &nbsp; ReturnCode returnCode = session.Connect(); <br>\
                    &nbsp; if (returnCode == ReturnCode.SOLCLIENT_OK) <br>\
                    &nbsp; { <br>\
                    &nbsp; &nbsp; // connected to the Solace message router <br>\
                    &nbsp; } <br>\
                } <br>`
            )
          case "Secured SMF":
            return this.createMarkup(
              `ContextFactoryProperties cfp = new ContextFactoryProperties() <br>\
                { <br>\
                SolClientLogLevel = SolLogLevel.Warning <br>\
                }; <br>\
                cfp.LogToConsoleError(); <br>\
                ContextFactory.Instance.Init(cfp); <br>\
                <br>\
                SessionProperties sessionProps = new SessionProperties() <br>\
                { <br>\
                    &nbsp; Host = "<span style="color: #00AD93;">${
                      this.state.url === "localhost"
                        ? "tcps://" + this.state.url + ":55443"
                        : this.state.url
                    }</span>", <br>\
                    &nbsp; VPNName = "<span style="color: #00AD93;">${
                      this.state.vpn
                    }</span>", <br>\
                    &nbsp; UserName = "<span style="color: #00AD93;">${
                      this.state.username
                    }</span>", <br>\
                    &nbsp; Password = <span style="color: #00AD93;">${
                      this.state.password
                    }</span>", <br>\
                    &nbsp; ReconnectRetries = DefaultReconnectRetries <br>\
                }; <br>\
                <br>\
                using (IContext context = ContextFactory.Instance.CreateContext(new ContextProperties(), null)) <br>\
                using (ISession session = context.CreateSession(sessionProps, HandleMessage, null)) <br>\
                { <br>\
                    &nbsp; ReturnCode returnCode = session.Connect(); <br>\
                    &nbsp; if (returnCode == ReturnCode.SOLCLIENT_OK) <br>\
                    &nbsp; { <br>\
                    &nbsp; &nbsp; // connected to the Solace message router <br>\
                    &nbsp; } <br>\
                } <br>`
            )
          case "Compressed SMF":
            return this.createMarkup(
              `ContextFactoryProperties cfp = new ContextFactoryProperties() <br>\
              { <br>\
              SolClientLogLevel = SolLogLevel.Warning <br>\
              }; <br>\
              cfp.LogToConsoleError(); <br>\
              ContextFactory.Instance.Init(cfp); <br>\
              <br>\
              SessionProperties sessionProps = new SessionProperties() <br>\
              { <br>\
                  &nbsp; Host = "<span style="color: #00AD93;">${
                    this.state.url === "localhost"
                      ? "tcp://" + this.state.url + ":55003"
                      : this.state.url
                  }</span>", <br>\
                  &nbsp; VPNName = "<span style="color: #00AD93;">${
                    this.state.vpn
                  }</span>", <br>\
                  &nbsp; UserName = "<span style="color: #00AD93;">${
                    this.state.username
                  }</span>", <br>\
                  &nbsp; Password = <span style="color: #00AD93;">${
                    this.state.password
                  }</span>", <br>\
                  &nbsp; ReconnectRetries = DefaultReconnectRetries, <br>\
                  &nbsp; <span style="color: #508A4D;">// The valid range is 1..9, where 1 is less compression (fastest) <br>\
                  &nbsp; // and 9 is most compression (slowest) </span> <br>\
                  &nbsp; CompressionLevel = 9 <br>\
              }; <br>\
              <br>\
              using (IContext context = ContextFactory.Instance.CreateContext(new ContextProperties(), null)) <br>\
              using (ISession session = context.CreateSession(sessionProps, HandleMessage, null)) <br>\
              { <br>\
                  &nbsp; ReturnCode returnCode = session.Connect(); <br>\
                  &nbsp; if (returnCode == ReturnCode.SOLCLIENT_OK) <br>\
                  &nbsp; { <br>\
                  &nbsp; &nbsp; <span style="color: #508A4D;">// connected to the Solace message router </span> <br>\
                  &nbsp; } <br>\
              } <br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solace-java-api":
        switch (this.state.protocol) {
          case "SMF":
            return this.createMarkup(
              `final Properties properties = new Properties(); <br>\
                    properties.setProperty(TransportLayerProperties.HOST, "<span style="color: #00AD93;">${
                    this.state.url === "localhost"
                      ? "tcp://" + this.state.url + ":55555"
                      : this.state.url
                  }</span>"); <br>\
                  properties.setProperty(ServiceProperties.VPN_NAME, "<span style="color: #00AD93;">${
                    this.state.vpn
                  }</span>"); <br>\
                  properties.setProperty(AuthenticationProperties.SCHEME_BASIC_USER_NAME, "<span style="color: #00AD93;">${
                    this.state.username
                  }</span>"); <br>\
                  properties.setProperty(AuthenticationProperties.SCHEME_BASIC_PASSWORD, "<span style="color: #00AD93;">${
                    this.state.password
                  }</span>"); <br>\
                  final MessagingService messagingService = MessagingService.builder(ConfigurationProfile.V1) <br>\
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.build() <br>\
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.fromProperties(properties) <br>\
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.connect(); <br>`
            )
          case "Secured SMF":
            return this.createMarkup(
              `final Properties properties = new Properties(); <br>\
                    properties.setProperty(TransportLayerProperties.HOST, "<span style="color: #00AD93;">${
                    this.state.url === "localhost"
                      ? "tcps://" + this.state.url + ":55554"
                      : this.state.url
                  }</span>"); <br>\
                  properties.setProperty(ServiceProperties.VPN_NAME, "<span style="color: #00AD93;">${
                    this.state.vpn
                  }</span>"); <br>\
                  properties.setProperty(AuthenticationProperties.SCHEME_BASIC_USER_NAME, "<span style="color: #00AD93;">${
                    this.state.username
                  }</span>"); <br>\
                  properties.setProperty(AuthenticationProperties.SCHEME_BASIC_PASSWORD, "<span style="color: #00AD93;">${
                    this.state.password
                  }</span>"); <br>\
                  final MessagingService messagingService = MessagingService.builder(ConfigurationProfile.V1) <br>\
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.build() <br>\
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.fromProperties(properties) <br>\
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.connect(); <br>`
            )
          case "Compressed SMF":
            return this.createMarkup(
              `final Properties properties = new Properties(); <br>\
                    properties.setProperty(TransportLayerProperties.HOST, "<span style="color: #00AD93;">${
                    this.state.url === "localhost"
                      ? "tcp://" + this.state.url + ":55555"
                      : this.state.url
                  }</span>"); <br>\
                  properties.setProperty(ServiceProperties.VPN_NAME, "<span style="color: #00AD93;">${
                    this.state.vpn
                  }</span>"); <br>\
                  properties.setProperty(AuthenticationProperties.SCHEME_BASIC_USER_NAME, "<span style="color: #00AD93;">${
                    this.state.username
                  }</span>"); <br>\
                  properties.setProperty(AuthenticationProperties.SCHEME_BASIC_PASSWORD, "<span style="color: #00AD93;">${
                    this.state.password
                  }</span>"); <br>\
                  properties.setProperty(TransportLayerProperties.COMPRESSION_LEVEL, "20"); <br>\
                  final MessagingService messagingService = MessagingService.builder(ConfigurationProfile.V1) <br>\
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.build() <br>\
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.fromProperties(properties) <br>\
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.connect(); <br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solace-jcsmp-api":
        switch (this.state.protocol) {
          case "SMF":
            return this.createMarkup(
              `final JCSMPProperties properties = new JCSMPProperties(); <br>\
                  properties.setProperty(JCSMPProperties.HOST, "<span style="color: #00AD93;">${
                    this.state.url === "localhost"
                      ? "tcp://" + this.state.url + ":55555"
                      : this.state.url
                  }</span>"); <br>\
                  properties.setProperty(JCSMPProperties.USERNAME, "<span style="color: #00AD93;">${
                    this.state.username
                  }</span>"); <br>\
                  properties.setProperty(JCSMPProperties.PASSWORD, "<span style="color: #00AD93;">${
                    this.state.password
                  }</span>"); <br>\
                  properties.setProperty(JCSMPProperties.VPN_NAME, "<span style="color: #00AD93;">${
                    this.state.vpn
                  }</span>"); <br>\
                  final JCSMPSession session = JCSMPFactory.onlyInstance().createSession(properties); <br>\
                  <br>\
                  session.connect();<br>`
            )
          case "Secured SMF":
            return this.createMarkup(
              `final JCSMPProperties properties = new JCSMPProperties(); <br>\
                properties.setProperty(JCSMPProperties.HOST, "<span style="color: #00AD93;">${
                  this.state.url === "localhost"
                    ? "tcps://" + this.state.url + ":55443"
                    : this.state.url
                }</span>"); <br>\
                properties.setProperty(JCSMPProperties.USERNAME, "<span style="color: #00AD93;">${
                  this.state.username
                }</span>"); <br>\
                properties.setProperty(JCSMPProperties.PASSWORD, "<span style="color: #00AD93;">${
                  this.state.password
                }</span>"); <br>\
                properties.setProperty(JCSMPProperties.VPN_NAME, "<span style="color: #00AD93;">${
                  this.state.vpn
                }</span>"); <br>\
                final JCSMPSession session = JCSMPFactory.onlyInstance().createSession(properties); <br>\
                <br>\
                session.connect();<br>`
            )
          case "Compressed SMF":
            return this.createMarkup(
              `final JCSMPProperties properties = new JCSMPProperties(); <br>\
                properties.setProperty(JCSMPProperties.HOST, "<span style="color: #00AD93;">${
                  this.state.url === "localhost"
                    ? "tcp://" + this.state.url + ":55003"
                    : this.state.url
                }</span>"); <br>\
                properties.setProperty(JCSMPProperties.USERNAME, "<span style="color: #00AD93;">${
                  this.state.username
                }</span>"); <br>\
                properties.setProperty(JCSMPProperties.PASSWORD, "<span style="color: #00AD93;">${
                  this.state.password
                }</span>"); <br>\
                properties.setProperty(JCSMPProperties.VPN_NAME, "<span style="color: #00AD93;">${
                  this.state.vpn
                }</span>"); <br>\
                JCSMPChannelProperties channelProperties = (JCSMPChannelProperties) properties.getProperty(JCSMPProperties.CLIENT_CHANNEL_PROPERTIES); <br>\
                channelProperties.setCompressionLevel(9); <br>\
                final JCSMPSession session = JCSMPFactory.onlyInstance().createSession(properties); <br>\
                <br>\
                session.connect();<br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solaceJavaRTOAPI":
        switch (this.state.protocol) {
          case "SMF":
            return this.createMarkup(
              `<span style="color: #508A4D;">// Solclient needs to be initialized before any other API calls. </span><br>\
                  Solclient.init(new String[0]); <br>\
                  <br>\
                  <span style="color: #508A4D;">// Context </span><br>\
                  final ContextHandle contextHandle = Solclient.Allocator.newContextHandle();<br>\
                  Solclient.createContextForHandle(contextHandle, new String[0]);<br>\
                  <br>\
                  <span style="color: #508A4D;">// A message callback to receive messages asynchronously </span><br>\
                  MessageCallback messageCallback = new MessageCallback() {<br>\
                      &nbsp;  @Override<br>\
                      &nbsp;  public void onMessage(Handle handle) {<br>\
                          &nbsp; &nbsp; System.out.println("Received message:");<br>\
                      &nbsp; }<br>\
                  };<br>\
                  <br>\
                  <span style="color: #508A4D;">// A session event callback to events such as connect/disconnect events</span><br>\
                  SessionEventCallback sessionEventCallback = new SessionEventCallback() {<br>\
                      &nbsp; @Override<br>\
                      &nbsp; public void onEvent(SessionHandle sessionHandle) {<br>\
                          &nbsp; &nbsp; System.out.println("Received SessionEvent:" + sessionHandle.getSessionEvent());<br>\
                      &nbsp; }<br>\
                  };<br>\
                  <br>\
                  <span style="color: #508A4D;">// Configure the session properties </span><br>\
                  ArrayList<String> sessionProperties = new ArrayList<String>();<br>\
                  sessionProperties.add(SessionHandle.PROPERTIES.HOST);<br>\
                  sessionProperties.add("<span style="color: #00AD93;">${
                    this.state.url === "localhost"
                      ? "tcp://" + this.state.url + ":55555"
                      : this.state.url
                  }</span>");<br>\
                  sessionProperties.add(SessionHandle.PROPERTIES.USERNAME);<br>\
                  sessionProperties.add("<span style="color: #00AD93;">${
                    this.state.username
                  }</span>");<br>\
                  sessionProperties.add(SessionHandle.PROPERTIES.PASSWORD);<br>\
                  sessionProperties.add("<span style="color: #00AD93;">${
                    this.state.password
                  }</span>");<br>\
                  sessionProperties.add(SessionHandle.PROPERTIES.VPN_NAME);<br>\
                  sessionProperties.add("<span style="color: #00AD93;">${
                    this.state.vpn
                  }</span>");<br>\
                  String[] props = new String[sessionProperties.size()];<br>\
                  <br>\
                  <span style="color: #508A4D;">// Instantiate a new SessionHandle instance </span><br>\
                  final SessionHandle sessionHandle = Solclient.Allocator.newSessionHandle();<br>\
                  <br>\
                  <span style="color: #508A4D;">// Create the Session</span><br>\
                  contextHandle.createSessionForHandle(sessionHandle, sessionProperties.toArray(props), messageCallback, sessionEventCallback);<br>\
                  <br>\
                  <span style="color: #508A4D;">// Connect the Session</span><br>\
                  sessionHandle.connect();<br>`
            )
          case "Secured SMF":
            return this.createMarkup(
              `<span style="color: #508A4D;">// Solclient needs to be initialized before any other API calls. </span><br>\
                Solclient.init(new String[0]); <br>\
                <br>\
                <span style="color: #508A4D;">// Context </span><br>\
                final ContextHandle contextHandle = Solclient.Allocator.newContextHandle();<br>\
                Solclient.createContextForHandle(contextHandle, new String[0]);<br>\
                <br>\
                <span style="color: #508A4D;">// A message callback to receive messages asynchronously </span><br>\
                MessageCallback messageCallback = new MessageCallback() {<br>\
                    &nbsp;  @Override<br>\
                    &nbsp;  public void onMessage(Handle handle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received message:");<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                <span style="color: #508A4D;">// A session event callback to events such as connect/disconnect events</span><br>\
                SessionEventCallback sessionEventCallback = new SessionEventCallback() {<br>\
                    &nbsp; @Override<br>\
                    &nbsp; public void onEvent(SessionHandle sessionHandle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received SessionEvent:" + sessionHandle.getSessionEvent());<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                <span style="color: #508A4D;">// Configure the session properties </span><br>\
                ArrayList<String> sessionProperties = new ArrayList<String>();<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.HOST);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.url === "localhost"
                    ? "tcps://" + this.state.url + ":55443"
                    : this.state.url
                }</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.USERNAME);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.username
                }</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.PASSWORD);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.password
                }</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.VPN_NAME);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.vpn
                }</span>");<br>\
                String[] props = new String[sessionProperties.size()];<br>\
                <br>\
                <span style="color: #508A4D;">// Instantiate a new SessionHandle instance </span><br>\
                final SessionHandle sessionHandle = Solclient.Allocator.newSessionHandle();<br>\
                <br>\
                <span style="color: #508A4D;">// Create the Session</span><br>\
                contextHandle.createSessionForHandle(sessionHandle, sessionProperties.toArray(props), messageCallback, sessionEventCallback);<br>\
                <br>\
                <span style="color: #508A4D;">// Connect the Session</span><br>\
                sessionHandle.connect();<br>`
            )
          case "Compressed SMF":
            return this.createMarkup(
              `<span style="color: #508A4D;">// Solclient needs to be initialized before any other API calls.</span> <br>\
                Solclient.init(new String[0]); <br>\
                <br>\
                <span style="color: #508A4D;">// Context </span><br>\
                final ContextHandle contextHandle = Solclient.Allocator.newContextHandle();<br>\
                Solclient.createContextForHandle(contextHandle, new String[0]);<br>\
                <br>\
                <span style="color: #508A4D;">// A message callback to receive messages asynchronously </span><br>\
                MessageCallback messageCallback = new MessageCallback() {<br>\
                    &nbsp;  @Override<br>\
                    &nbsp;  public void onMessage(Handle handle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received message:");<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                <span style="color: #508A4D;">// A session event callback to events such as connect/disconnect events </span><br>\
                SessionEventCallback sessionEventCallback = new SessionEventCallback() {<br>\
                    &nbsp; @Override<br>\
                    &nbsp; public void onEvent(SessionHandle sessionHandle) {<br>\
                        &nbsp; &nbsp; System.out.println("Received SessionEvent:" + sessionHandle.getSessionEvent());<br>\
                    &nbsp; }<br>\
                };<br>\
                <br>\
                <span style="color: #508A4D;">// Configure the Session properties </span><br>\
                ArrayList<String> sessionProperties = new ArrayList<String>();<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.HOST);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.url === "localhost"
                    ? "tcp://" + this.state.url + ":55003"
                    : this.state.url
                }</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.USERNAME);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.username
                }</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.PASSWORD);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.password
                }</span>");<br>\
                sessionProperties.add(SessionHandle.PROPERTIES.VPN_NAME);<br>\
                sessionProperties.add("<span style="color: #00AD93;">${
                  this.state.vpn
                }</span>");<br>\
                String[] props = new String[sessionProperties.size()];<br>\
                <br>\
                <span style="color: #508A4D;">// Instantiate a new SessionHandle instance </span><br>\
                final SessionHandle sessionHandle = Solclient.Allocator.newSessionHandle();<br>\
                <br>\
                <span style="color: #508A4D;">// Create the Session </span><br>\
                contextHandle.createSessionForHandle(sessionHandle, sessionProperties.toArray(props), messageCallback, sessionEventCallback);<br>\
                <br>\
                <span style="color: #508A4D;">// Connect the Session </span><br>\
                sessionHandle.connect();<br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solace-javascript-api":
        switch (this.state.protocol) {
          case "Web Messaging":
            return this.createMarkup(
              `var session = solace.SolclientFactory.createSession({ <br>\
              &nbsp;&nbsp; url: "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "ws://" + this.state.url + ":80"
                  : this.state.url
              }</span>", <br>\
              &nbsp;&nbsp; vpnName: "<span style="color: #00AD93;">${
                this.state.vpn
              }</span>", <br>\
              &nbsp;&nbsp; userName: "<span style="color: #00AD93;">${
                this.state.username
              }</span>", <br>\
              &nbsp;&nbsp; password: "<span style="color: #00AD93;">${
                this.state.password
              }</span>", <br>\
              }); <br>\
              try { <br>\
                  &nbsp;&nbsp; session.connect(); <br>\
              } catch (error) { <br>\
                  &nbsp;&nbsp; console.log(error); <br>\
              }`
            )
          case "Secured Web Messaging":
            return this.createMarkup(
              `var session = solace.SolclientFactory.createSession({ <br>\
              &nbsp;&nbsp; url: "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "wss://" + this.state.url + ":443"
                  : this.state.url
              }</span>", <br>\
              &nbsp;&nbsp; vpnName: "<span style="color: #00AD93;">${
                this.state.vpn
              }</span>", <br>\
              &nbsp;&nbsp; userName: "<span style="color: #00AD93;">${
                this.state.username
              }</span>", <br>\
              &nbsp;&nbsp; password: "<span style="color: #00AD93;">${
                this.state.password
              }</span>", <br>\
              }); <br>\
              try { <br>\
                  &nbsp;&nbsp; session.connect(); <br>\
              } catch (error) { <br>\
                  &nbsp;&nbsp; console.log(error); <br>\
              }`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solace-jms-api":
        switch (this.state.protocol) {
          case "SMF":
            return this.createMarkup(
              `SolConnectionFactory connectionFactory = SolJmsUtility.createConnectionFactory(); <br>\
                connectionFactory.setHost("<span style="color: #00AD93;">${
                  this.state.url === "localhost"
                    ? "tcp://" + this.state.url + ":55555"
                    : this.state.url
                }</span>"); <br>\
                connectionFactory.setUsername("<span style="color: #00AD93;">${
                  this.state.username
                }</span>"); <br>\
                connectionFactory.setPassword("<span style="color: #00AD93;">${
                  this.state.password
                }</span>"); <br>\
                connectionFactory.setVPN("<span style="color: #00AD93;">${
                  this.state.vpn
                }</span>"); <br>\
                <br>\
                Connection connection = connectionFactory.createConnection(); <br>\
                Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);<br>`
            )
          case "Secured SMF":
            return this.createMarkup(
              `SolConnectionFactory connectionFactory = SolJmsUtility.createConnectionFactory(); <br>\
              connectionFactory.setHost("<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "tcps://" + this.state.url + ":55443"
                  : this.state.url
              }</span>"); <br>\
              connectionFactory.setUsername("<span style="color: #00AD93;">${
                this.state.username
              }</span>"); <br>\
              connectionFactory.setPassword("<span style="color: #00AD93;">${
                this.state.password
              }</span>"); <br>\
              connectionFactory.setVPN("<span style="color: #00AD93;">${
                this.state.vpn
              }</span>"); <br>\
              <br>\
              Connection connection = connectionFactory.createConnection(); <br>\
              Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);<br>`
            )
          case "Compressed SMF":
            return this.createMarkup(
              `Hashtable&lt;String, Object&gt; env = new Hashtable&lt;String,Object&gt;(); <br>\
              env.put(SupportedProperty.SOLACE_JMS_COMPRESSION_LEVEL, 9); <br>\
              SolConnectionFactory connectionFactory = SolJmsUtility.createConnectionFactory(env); <br>\
              connectionFactory.setHost("<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "tcp://" + this.state.url + ":55003"
                  : this.state.url
              }</span>"); <br>\
              connectionFactory.setUsername("<span style="color: #00AD93;">${
                this.state.username
              }</span>"); <br>\
              connectionFactory.setPassword("<span style="color: #00AD93;">${
                this.state.password
              }</span>"); <br>\
              connectionFactory.setVPN("<span style="color: #00AD93;">${
                this.state.vpn
              }</span>"); <br>\
              <br>\
              Connection connection = connectionFactory.createConnection(); <br>\
              Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);<br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solace-node-api":
        switch (this.state.protocol) {
          case "Web Messaging":
            return this.createMarkup(
              `var session = solace.SolclientFactory.createSession({ <br>\
              &nbsp;&nbsp; url: "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "ws://" + this.state.url + ":80"
                  : this.state.url
              }</span>", <br>\
              &nbsp;&nbsp; vpnName: "<span style="color: #00AD93;">${
                this.state.vpn
              }</span>", <br>\
              &nbsp;&nbsp; userName: "<span style="color: #00AD93;">${
                this.state.username
              }</span>", <br>\
              &nbsp;&nbsp; password: "<span style="color: #00AD93;">${
                this.state.password
              }</span>", <br>\
              }); <br>\
              try { <br>\
                  &nbsp;&nbsp; session.connect(); <br>\
              } catch (error) { <br>\
                  &nbsp;&nbsp; console.log(error); <br>\
              }`
            )
          case "Secured Web Messaging":
            return this.createMarkup(
              `var session = solace.SolclientFactory.createSession({ <br>\
              &nbsp;&nbsp; url: "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "wss://" + this.state.url + ":443"
                  : this.state.url
              }</span>", <br>\
              &nbsp;&nbsp; vpnName: "<span style="color: #00AD93;">${
                this.state.vpn
              }</span>", <br>\
              &nbsp;&nbsp; userName: "<span style="color: #00AD93;">${
                this.state.username
              }</span>", <br>\
              &nbsp;&nbsp; password: "<span style="color: #00AD93;">${
                this.state.password
              }</span>", <br>\
              }); <br>\
              try { <br>\
                  &nbsp;&nbsp; session.connect(); <br>\
              } catch (error) { <br>\
                  &nbsp;&nbsp; console.log(error); <br>\
              }`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solaceQpid2API":
        switch (this.state.protocol) {
          case "AMQP":
            return this.createMarkup(
              `ConnectionFactory connectionFactory = new JmsConnectionFactory("<span style="color: #00AD93;">${
                this.state.username
              }</span>", "<span style="color: #00AD93;">${
                this.state.password
              }</span>", "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "amqp://" + this.state.url + ":5672"
                  : this.state.url
              }</span>"); <br>\
                JMSContext context = connectionFactory.createContext(); <br>`
            )
          case "Secured AMQP":
            return this.createMarkup(
              `ConnectionFactory connectionFactory = new JmsConnectionFactory("<span style="color: #00AD93;">${
                this.state.username
              }</span>", "<span style="color: #00AD93;">${
                this.state.password
              }</span>", "<span style="color: #00AD93;">${
                this.state.url === "localhost"
                  ? "amqps://" + this.state.url + ":5671"
                  : this.state.url
              }</span>"); <br>\
                JMSContext context = connectionFactory.createContext(); <br>`
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
      case "solacePythonAPI":
        switch (this.state.protocol) {
          case "SMF":
            return this.createMarkup(
              `broker_props = {<br>\
                &nbsp; "solace.messaging.transport.host": "<span style="color: #00AD93;">${
                          this.state.url === "localhost"
                            ? "localhost"
                            : this.state.url
                        }</span>",<br>\
                &nbsp; "solace.messaging.service.vpn-name": "<span style="color: #00AD93;">${
                          this.state.vpn === "default"
                            ? "default"
                            : this.state.vpn
                        }</span>",<br>\
                &nbsp; "solace.messaging.authentication.scheme.basic.username": "<span style="color: #00AD93;">${
                          this.state.username === "default"
                            ? "default"
                            : this.state.username
                        }</span>",<br>\
                &nbsp; "solace.messaging.authentication.scheme.basic.password": "<span style="color: #00AD93;">${
                          this.state.password === "default"
                            ? "default"
                            : this.state.password
                        }</span>",<br>\
                &nbsp; }<br>\
            <br>\
            messaging_service = MessagingService.builder().from_properties(broker_props)\<br>\
                                &nbsp; .with_reconnection_retry_strategy(RetryStrategy.parametrized_retry(20,3))\<br>\
                                &nbsp; .build()<br>\
            <br>\
            messaging_service.connect()<br>\
            `
            )
          case "Secured SMF":
            return this.createMarkup(
              `from solace.messaging.messaging_service import MessagingService, RetryStrategy <br>\ 
              from solace.messaging.config.transport_security_strategy import TLS <br><br>\
                broker_props = {<br>\
                &nbsp; "solace.messaging.transport.host": "<span style="color: #00AD93;">${
                          this.state.url === "localhost"
                            ? "localhost"
                            : this.state.url
                        }</span>",<br>\
                &nbsp; "solace.messaging.service.vpn-name": "<span style="color: #00AD93;">${
                          this.state.vpn === "default"
                            ? "default"
                            : this.state.vpn
                        }</span>",<br>\
                &nbsp; "solace.messaging.authentication.scheme.basic.username": "<span style="color: #00AD93;">${
                          this.state.username === "default"
                            ? "default"
                            : this.state.username
                        }</span>",<br>\
                &nbsp; "solace.messaging.authentication.scheme.basic.password": "<span style="color: #00AD93;">${
                          this.state.password === "default"
                            ? "default"
                            : this.state.password
                        }</span>",<br>\
                &nbsp; }<br>\
            <br>\
            transport_security_strategy = TLS.create() \\ <br>\
            &nbsp; .with_certificate_validation(True, False, <br>\
              &nbsp; &nbsp; &nbsp; &nbsp; trust_store_file_path="<span style="color: #00AD93;">path_to_trusted_stor_dir</span>") <br>\
            <br>\
            messaging_service = MessagingService.builder().from_properties(broker_props)\\ <br>\
                                &nbsp; .with_reconnection_retry_strategy(RetryStrategy.parametrized_retry(20,3))\\ <br>\
                                &nbsp; .with_transport_security_strategy(transport_security_strategy)\\ <br>\
                                &nbsp; .build()<br>\
            <br>\
            messaging_service.connect()<br>\
            `
            )
          case "Compressed SMF":
            return this.createMarkup(
              `broker_props = {<br>\
                &nbsp; "solace.messaging.transport.host.compressed": "<span style="color: #00AD93;">${
                          this.state.url === "localhost"
                            ? "localhost"
                            : this.state.url
                        }</span>",<br>\
                &nbsp; "solace.messaging.service.vpn-name": "<span style="color: #00AD93;">${
                          this.state.vpn === "default"
                            ? "default"
                            : this.state.vpn
                        }</span>",<br>\
                &nbsp; "solace.messaging.authentication.scheme.basic.username": "<span style="color: #00AD93;">${
                          this.state.username === "default"
                            ? "default"
                            : this.state.username
                        }</span>",<br>\
                &nbsp; "solace.messaging.authentication.scheme.basic.password": "<span style="color: #00AD93;">${
                          this.state.password === "default"
                            ? "default"
                            : this.state.password
                        }</span>",<br>\
                &nbsp; }<br>\
            <br>\
            messaging_service = MessagingService.builder().from_properties(broker_props)\\ <br>\
                                &nbsp; .with_reconnection_retry_strategy(RetryStrategy.parametrized_retry(20,3))\\ <br>\
                                &nbsp; .with_message_compression(compression_range=1)\\ <br>\
                                &nbsp; .build()<br>\
            <br>\
            messaging_service.connect()<br>\
            `
            )
          default:
            return this.createMarkup("<span>Coming Soon...</span>")
        }
        case "solaceGoAPI":
          switch (this.state.protocol) {
            case "SMF":
              return this.createMarkup(
                `
                brokerConfig := config.ServicePropertyMap{<br>\
                  &nbsp; config.TransportLayerPropertyHost:                "<span style="color: #00AD93;">${
                          this.state.url === "localhost"
                            ? "localhost"
                            : this.state.url
                        }</span>",<br>\
                  &nbsp; config.ServicePropertyVPNName:                "<span style="color: #00AD93;">${
                          this.state.vpn === "default"
                            ? "default"
                            : this.state.vpn
                        }</span>",<br>\
                  &nbsp; config.AuthenticationPropertySchemeBasicUserName:                "<span style="color: #00AD93;">${
                          this.state.username === "default"
                            ? "default"
                            : this.state.username
                        }</span>",<br>\
                  &nbsp; config.AuthenticationPropertySchemeBasicPassword:                "<span style="color: #00AD93;">${
                          this.state.password === "default"
                            ? "default"
                            : this.state.password
                        }</span>",<br>\
                }<br>\

                messagingService, err := messaging.NewMessagingServiceBuilder().<br>\
                &nbsp; FromConfigurationProvider(brokerConfig).<br>\
                &nbsp; Build()
              <br>\
              <br>\
              // Connect to the messaging service<br>\
              if err := messagingService.Connect(); err != nil {<br>\
                &nbsp; panic(err)<br>\
              }<br>\
              `
              )
            case "Secured SMF":
              return this.createMarkup(
                `
                brokerConfig := config.ServicePropertyMap{<br>\
                  &nbsp; config.TransportLayerPropertyHost:                "<span style="color: #00AD93;">${
                          this.state.url === "localhost"
                            ? "localhost"
                            : this.state.url
                        }</span>",<br>\
                  &nbsp; config.ServicePropertyVPNName:                "<span style="color: #00AD93;">${
                          this.state.vpn === "default"
                            ? "default"
                            : this.state.vpn
                        }</span>",<br>\
                  &nbsp; config.AuthenticationPropertySchemeBasicUserName:                "<span style="color: #00AD93;">${
                          this.state.username === "default"
                            ? "default"
                            : this.state.username
                        }</span>",<br>\
                  &nbsp; config.AuthenticationPropertySchemeBasicPassword:                "<span style="color: #00AD93;">${
                          this.state.password === "default"
                            ? "default"
                            : this.state.password
                        }</span>",<br>\
                }<br>\
                messagingService, err := messaging.NewMessagingServiceBuilder().<br>\
                &nbsp; FromConfigurationProvider(brokerConfig).<br>\
                &nbsp; WithTransportSecurityStrategy(config.NewTransportSecurityStrategy().WithCertificateValidation(false, true, "<span style="color: #00AD93;">path_to_trusted_stor_dir</span>", "")).<br>\
                &nbsp; Build()<br>\
                <br>\
                // Connect to the messaging service<br>\
                if err := messagingService.Connect(); err != nil {<br>\
                  &nbsp;panic(err)<br>\
                }<br>\
              `
              )
            case "Compressed SMF":
              return this.createMarkup(
                `
                brokerConfig := config.ServicePropertyMap{<br>\
                  &nbsp; config.TransportLayerPropertyHost:                "<span style="color: #00AD93;">${
                          this.state.url === "localhost"
                            ? "localhost"
                            : this.state.url
                        }</span>",<br>\
                  &nbsp; config.ServicePropertyVPNName:                "<span style="color: #00AD93;">${
                          this.state.vpn === "default"
                            ? "default"
                            : this.state.vpn
                        }</span>",<br>\
                  &nbsp; config.AuthenticationPropertySchemeBasicUserName:                "<span style="color: #00AD93;">${
                          this.state.username === "default"
                            ? "default"
                            : this.state.username
                        }</span>",<br>\
                  &nbsp; config.AuthenticationPropertySchemeBasicPassword:                "<span style="color: #00AD93;">${
                          this.state.password === "default"
                            ? "default"
                            : this.state.password
                        }</span>",<br>\
                }<br>\

                messagingService, err := messaging.NewMessagingServiceBuilder().<br>\
                &nbsp; FromConfigurationProvider(brokerConfig).<br>\
                &nbsp; WithMessageCompression(1).<br>\
                &nbsp; Build()
                
              <br>\
              <br>\
              // Connect to the messaging service<br>\
              if err := messagingService.Connect(); err != nil {<br>\
                &nbsp;panic(err)<br>\
              }<br>\
              `
              )
            default:
              return this.createMarkup("<span>Coming Soon...</span>")
          }
      default:
        return this.createMarkup("<span>Coming Soon...</span>")
    }
  }

  selectSampleCode(event) {
    //'event' may not exist by the time we want to use it, so we need to store it in a variable
    let newProtocol = event.currentTarget.value
    this.setState(state => ({ protocol: newProtocol }))
  }

  copyCode(elementId) {
    let code = document.getElementById(elementId).innerText
    var tempCodeElement = document.createElement("textarea")
    document.body.appendChild(tempCodeElement)
    tempCodeElement.value = code.replace(/<br\s*[/]?>/gi, "\r\n")
    tempCodeElement.select()
    document.execCommand("copy", false)
    this.setState({ copied: !this.state.copied })
    tempCodeElement.remove()
    setTimeout(() => {
      this.setState({ copied: !this.state.copied })
    }, 1000)
  }

  render() {
    if (this.state.step === 1) {
      if (this.selectedLibrary.step1) {
        return (
          <div className="w100">
            <div>
              Here are a few easy ways to get the{" "}
              {this.selectedLibrary.step1.name}. If your environment differs
              then adjust the build instructions appropriately.
            </div>
            <br />
            {this.selectedLibrary.step1.getAPI.map((step, index) => {
              return (
                <div key={index}>
                  <div className="title">Get the API: {step.title}</div>
                  {step.description ? (
                    <div
                      className="description"
                      dangerouslySetInnerHTML={this.createMarkup(
                        step.description
                      )}
                    />
                  ) : (
                    ""
                  )}
                  {step.code
                    ? step.code.map((codeSample, index) => {
                        return (
                          <div className="sample" key={index}>
                            <button
                              onClick={() =>
                                this.copyCode(
                                  "code-step-1" + step.title + index
                                )
                              }
                              className="tooltip copy"
                            >
                              {this.state.copied ? (
                                <span className="tooltiptext">Copied!</span>
                              ) : (
                                <span className="tooltiptext">Copy Code</span>
                              )}
                              <i className="fa fa-copy"></i>
                            </button>
                            <div
                              className="code"
                              id={"code-step-1" + step.title + index}
                              dangerouslySetInnerHTML={this.createMarkup(
                                codeSample
                              )}
                            />
                          </div>
                        )
                      })
                    : ""}
                  {step.footer ? (
                    <div
                      className="description"
                      dangerouslySetInnerHTML={this.createMarkup(step.footer)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )
            })}
          </div>
        )
      } else {
        return <div className="coming-soon">Coming soon...</div>
      }
    } else if (this.state.step === 2) {
      if (this.selectedLibrary.step2) {
        return (
          <div className="w100">
            <div>
              {this.selectedLibrary.step2.introduction.map(intro => {
                return (
                  <div
                    key={intro}
                    className="description"
                    dangerouslySetInnerHTML={this.createMarkup(intro)}
                  ></div>
                )
              })}
            </div>
            <div className="sample-toggle">
              <span style={{ paddingRight: "10px" }}>Host URI</span>
              <select onChange={this.selectSampleCode}>
                {this.selectedLibrary.step2.code.map(codeSample => {
                  return (
                    <option
                      key={codeSample.protocol}
                      value={codeSample.protocol}
                    >
                      {codeSample.protocol}
                    </option>
                  )
                })}
              </select>
              <img
                className="caret"
                src={require("./images/arrow_down.svg")}
                alt=""
              />
            </div>
            {this.selectedLibrary.step2.code ? (
              <div className="sample">
                <button
                  onClick={() => this.copyCode("code-step-2")}
                  className="tooltip copy"
                >
                  {this.state.copied ? (
                    <span className="tooltiptext">Copied!</span>
                  ) : (
                    <span className="tooltiptext">Copy Code</span>
                  )}
                  <i className="fa fa-copy"></i>
                </button>
                <div
                  className="code"
                  id="code-step-2"
                  dangerouslySetInnerHTML={this.createCodeMarkup()}
                ></div>
              </div>
            ) : (
              ""
            )}
            <div>
              {this.selectedLibrary.step2.conclusion.map(conclusion => {
                return (
                  <div key={conclusion} className="description">
                    {conclusion}
                  </div>
                )
              })}
            </div>
          </div>
        )
      } else {
        return <div className="coming-soon">Coming soon...</div>
      }
    } else if (this.state.step === 3) {
      if (this.selectedLibrary.step3) {
        return (
          <div>
            {this.selectedLibrary.links.tutorial ? (
              <div className="title">
                {this.selectedLibrary.id === "springBootJavaAPI" ||
                this.selectedLibrary.id === "springBootJmsAPI"
                  ? ""
                  : "Tutorials"}
              </div>
            ) : (
              ""
            )}
            <div className="tutorials">
              {this.selectedLibrary.step3.tutorials.map(tutorial => {
                return (
                  <a
                    key={tutorial.name}
                    href={tutorial.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tutorial"
                  >
                    <img
                      src={require("" + tutorial.logo)}
                      className="image"
                      alt=""
                    />
                    <label
                      dangerouslySetInnerHTML={this.createMarkup(tutorial.name)}
                    ></label>
                  </a>
                )
              })}
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="coming-soon">Coming soon...</div>
          </div>
        )
      }
    }
  }
}
