/* eslint-disable */
export default {
  id: "solaceQpid2API",
  componentName: "connectivity-detail-template-v1",
  displayName: "QPID JMS 2.0",
  protocol: {
    id: "amqp",
    name: "AMQP",
    logo: "./images/qpid.png",
    usesVPN: false,
  },
  language: {
    name: "Java",
    logo: "./images/java-logo.png",
  },
  links: {
    tutorial:
      "https://tutorials.solace.dev/java-amqp-qpid-jms2/publish-subscribe/",
    api: "https://docs.oracle.com/javaee/7/api/javax/jms/package-summary.html",
    download: [
      {
        name: "Apache QPID JMS 2.0",
        url:
          "http://www.apache.org/dyn/closer.lua/qpid/jms/0.31.0/apache-qpid-jms-0.31.0-bin.tar.gz",
      },
    ],
  },
  step1: {
    name: "Apache QPID JMS client",
    getAPI: [
      {
        title: "Using Gradle",
        code: [
          'dependencies { <br>\
                    &nbsp; compile("org.apache.qpid:qpid-jms-client:0.27.0") <br>\
                }',
        ],
      },
      {
        title: "Using Maven",
        code: [
          "&lt;dependency&gt; <br>\
                &nbsp; &lt;groupId&gt;org.apache.qpid&lt;/groupId&gt; <br>\
                &nbsp; &lt;artifactId&gt;qpid-jms-client&lt;/artifactId&gt; <br>\
                &nbsp; &lt;version&gt;0.27.0&lt;/version&gt; <br>\
            &lt;/dependency&gt;",
        ],
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must start a JMS connection",
    ],
    code: [
      {
        protocol: "AMQP",
        sample:
          '\
                ConnectionFactory connectionFactory = new JmsConnectionFactory("<span class="value">${username}</span>", "<span class="value">${password}</span>", "<span class="value">${url}</span>"); <br>\
                JMSContext context = connectionFactory.createContext(); <br>\
                ',
      },
      {
        protocol: "Secured AMQP",
        sample:
          '\
                ConnectionFactory connectionFactory = new JmsConnectionFactory("<span class="value">${username}</span>", "<span class="value">${password}</span>", "<span class="value">${url}</span>"); <br>\
                JMSContext context = connectionFactory.createContext(); <br>\
                ',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://tutorials.solace.dev/java-amqp-qpid-jms2/publish-subscribe/",
      },
      {
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url:
          "https://tutorials.solace.dev/java-amqp-qpid-jms2/persistence-with-queues/",
      },
      {
        name: "Request/ <br>\
                        Reply",
        logo: "./images/request-reply.svg",
        url:
          "https://tutorials.solace.dev/java-amqp-qpid-jms2/request-reply/",
      },
      {
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url:
          "https://tutorials.solace.dev/java-amqp-qpid-jms2/confirmed-delivery/",
      },
      {
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url:
          "https://tutorials.solace.dev/java-amqp-qpid-jms2/topic-to-queue-mapping/",
      },
    ],
  },
}
