import React from "react"
export default class ProtocolLibrary extends React.Component {
  constructor(props) {
    super(props)
    this.library = props.data
  }

  render() {
    return (
      <div style={{ display: "flex" }}>
        <div className="description">
          <div className="name">{this.library.displayName}</div>
          {this.library.languages}
        </div>
        <div className="links">
          <div className="get-started">Get Started</div>
        </div>
      </div>
    )
  }
}
