/* eslint-disable */
export default {
  id: "solace-jms-api",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace JMS API",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Java",
    logo: "./images/java-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/jms/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/jms/index.html",
    download: [
      {
        name: "Maven Repository",
        group: "none",
        url: "https://mvnrepository.com/artifact/com.solacesystems/sol-jms",
      },
    ],
  },
  step1: {
    name: "Java API",
    getAPI: [
      {
        title: "Using Gradle",
        code: ['compile("com.solacesystems:sol-jms:10.2.1")'],
      },
      {
        title: "Using Maven",
        code: [
          "&lt;dependency&gt; <br>\
                &nbsp&nbsp &lt;groupId&gt;com.solacesystems&lt/groupId&gt <br>\
                &nbsp&nbsp &lt;artifactId&gt;sol-jms&lt;/artifactId&gt; <br>\
                &nbsp&nbsp &lt;version&gt;10.2.1&lt;/version&gt; <br>\
                &lt;/dependency&gt",
        ],
      },
      {
        title: "Using Solace Developer Portal",
        description:
          'The Java API library can be downloaded <a href="https://solace.com/downloads/?fwp_downloads=solace-apis/" target="_blank">here</a>. The JMS API is distributed as a zip file containing the required jars, API documentation, and examples.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must connect to Solace messaging. In JMS, a client connects by creating a Connection from the ConnectionFactory. Then a JMS Session is used as a factory for consumers and producers.",
      "The following code shows how to create a connection using a programmatically created ConnectionFactory. You can learn more about other ways to create ConnectionFactories by referring to Solace JMS Documentation - Obtaining Connection Factories.",
    ],
    code: [
      {
        protocol: "SMF",
        sample:
          'SolConnectionFactory connectionFactory = SolJmsUtility.createConnectionFactory(); <br>\
                       connectionFactory.setHost("<span class="value">${url}</span>"); <br>\
                       connectionFactory.setUsername("<span class="value">${username}</span>"); <br>\
                       connectionFactory.setPassword("<span class="value">${password}</span>"); <br>\
                       connectionFactory.setVPN(<span class="value">${vpn}</span>"); <br>\
                       <br>\
                       Connection connection = connectionFactory.createConnection(); <br>\
                       Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);<br>\
                       ',
      },
      {
        protocol: "Secured SMF",
        sample:
          'SolConnectionFactory connectionFactory = SolJmsUtility.createConnectionFactory(); <br>\
                       connectionFactory.setHost("<span class="value">${url}</span>"); <br>\
                       connectionFactory.setUsername("<span class="value">${username}</span>"); <br>\
                       connectionFactory.setPassword("<span class="value">${password}</span>"); <br>\
                       connectionFactory.setVPN(<span class="value">${vpn}</span>"); <br>\
                       <br>\
                       Connection connection = connectionFactory.createConnection(); <br>\
                       Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);<br>\
                       ',
      },
      {
        protocol: "Compressed SMF",
        sample:
          'Hashtable&lt;String, Object&gt; env = new Hashtable&lt;String,Object&gt;(); <br>\
               		   env.put(SupportedProperty.SOLACE_JMS_COMPRESSION_LEVEL, 9); <br>\
               		   SolConnectionFactory connectionFactory = SolJmsUtility.createConnectionFactory(env); <br>\
                       connectionFactory.setHost("<span class="value">${url}</span>"); <br>\
                       connectionFactory.setUsername("<span class="value">${username}</span>"); <br>\
                       connectionFactory.setPassword("<span class="value">${password}</span>"); <br>\
                       connectionFactory.setVPN(<span class="value">${vpn}</span>"); <br>\
                       <br>\
                       Connection connection = connectionFactory.createConnection(); <br>\
                       Session session = connection.createSession(false, Session.AUTO_ACKNOWLEDGE);<br>\
                       ',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url: "https://tutorials.solace.dev/jms/publish-subscribe/",
      },
      {
        id: "persistence-queues",
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url:
          "https://tutorials.solace.dev/jms/persistence-with-queues/",
      },
      {
        id: "request-reply",
        name: "Request/ <br>\
                        Reply",
        logo: "./images/request-reply.svg",
        url: "https://tutorials.solace.dev/jms/request-reply/",
      },
      {
        id: "confirmed-delivery",
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url:
          "https://tutorials.solace.dev/jms/confirmed-delivery/",
      },
      {
        id: "topic-mapping",
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url:
          "https://tutorials.solace.dev/jms/topic-to-queue-mapping/",
      },
      {
        id: "jms-jndi",
        name: "Obtaining JMS<br>\
                        objects using JNDI",
        logo: "./images/jms-jndi.svg",
        url: "https://tutorials.solace.dev/jms/using-jndi/",
      },
    ],
  },
}
