/* eslint-disable */
export default {
  id: "solace-jcsmp-api",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace JCSMP API",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "JCSMP",
    logo: "./images/java-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/jcsmp/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/java/index.html",
    download: [
      {
        name: "Maven Repository",
        group: "none",
        url: "https://mvnrepository.com/artifact/com.solacesystems/sol-jcsmp",
      },
    ],
  },
  step1: {
    name: "JCSMP API",
    getAPI: [
      {
        title: "Using Gradle",
        code: ['compile("com.solacesystems:sol-jcsmp:10.+")'],
      },
      {
        title: "Using Maven",
        code: [
          "&lt;dependency&gt; <br>\
                &nbsp&nbsp &lt;groupId&gt;com.solacesystems&lt/groupId&gt <br>\
                &nbsp&nbsp &lt;artifactId&gt;sol-jcsmp&lt;/artifactId&gt; <br>\
                &nbsp&nbsp &lt;version&gt;[10,)&lt;/version&gt; <br>\
                &lt;/dependency&gt",
        ],
      },
      {
        title: "Using Solace Developer Portal",
        description:
          'The JCSMP API library can be downloaded <a href="https://mvnrepository.com/artifact/com.solacesystems/sol-jcsmp" target="_blank">here</a>. The Java API is distributed as a zip file containing the required jars. API documentation, and examples.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must connect a Solace session. The Solace session is the basis for all client communication with the Solace messaging router.",
    ],
    code: [
      {
        protocol: "SMF",
        sample:
          'final JCSMPProperties properties = new JCSMPProperties(); <br>\
                       properties.setProperty(JCSMPProperties.HOST, "<span class="value">${url}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.USERNAME, "<span class="value">${username}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.PASSWORD, "<span class="value">${password}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.VPN_NAME, "<span class="value">${vpn}</span>"); <br>\
                       final JCSMPSession session = JCSMPFactory.onlyInstance().createSession(properties); <br>\
                       <br>\
                       session.connect();<br>\
                       ',
      },
      {
        protocol: "Secured SMF",
        sample:
          'final JCSMPProperties properties = new JCSMPProperties(); <br>\
                       properties.setProperty(JCSMPProperties.HOST, "<span class="value">${url}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.USERNAME, "<span class="value">${username}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.PASSWORD, "<span class="value">${password}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.VPN_NAME, "<span class="value">${vpn}</span>"); <br>\
                       final JCSMPSession session = JCSMPFactory.onlyInstance().createSession(properties); <br>\
                       <br>\
                       session.connect();<br>\
                       ',
      },
      {
        protocol: "Compressed SMF",
        sample:
          'final JCSMPProperties properties = new JCSMPProperties(); <br>\
                       properties.setProperty(JCSMPProperties.HOST, "<span class="value">${url}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.USERNAME, "<span class="value">${username}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.PASSWORD, "<span class="value">${password}</span>"); <br>\
                       properties.setProperty(JCSMPProperties.VPN_NAME, "<span class="value">${vpn}</span>"); <br>\
                       JCSMPChannelProperties channelProperties = (JCSMPChannelProperties) properties.getProperty(JCSMPProperties.CLIENT_CHANNEL_PROPERTIES); <br>\
					   channelProperties.setCompressionLevel(9); <br>\
                       final JCSMPSession session = JCSMPFactory.onlyInstance().createSession(properties); <br>\
                       <br>\
                       session.connect();<br>\
                       ',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url: "https://tutorials.solace.dev/jcsmp/publish-subscribe/",
      },
      {
        id: "persistence-queues",
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url: "https://tutorials.solace.dev/jcsmp/persistence-with-queues/",
      },
      {
        id: "request-reply",
        name: "Request/ <br>\
                        Reply",
        logo: "./images/request-reply.svg",
        url: "https://tutorials.solace.dev/jcsmp/request-reply/",
      },
      {
        id: "confirmed-delivery",
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url: "https://tutorials.solace.dev/jcsmp/confirmed-delivery/",
      },
      {
        id: "topic-mapping",
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url: "https://tutorials.solace.dev/jcsmp/topic-to-queue-mapping/",
      },
    ],
  },
}
