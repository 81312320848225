import React from "react"
import Accordion from "./accordion.js"
import "../../css/apis-protocols.css"
import ConnectionDetails from "./connectivity-details-v1.js"
import Description from "./description.js"
import { Container, Row, Col } from "reactstrap"

class ContentPicker extends React.Component {
  constructor(props) {
    super(props)
    this.toggleConnectDetailsFn = this.toggleConnectDetails.bind(this)
    this.state = {
      showAccordion: true,
      library: "",
    }
  }

  toggleConnectDetails(libraryID) {
    let topOfSection = document.getElementById("apis-and-protocols")
    if (topOfSection) {
      topOfSection.scrollIntoView({ behavior: "smooth" })
    }
    let state = { showAccordion: !this.state.showAccordion, library: libraryID }
    this.setState(state)
  }

  pickContent() {
    if (this.state.showAccordion) {
      return (
        <div>
          <Description />
          <Accordion toggleConnectDetailsFn={this.toggleConnectDetailsFn} />
        </div>
      )
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ConnectionDetails
            libraryID={this.state.library}
            toggleConnectDetailsFn={this.toggleConnectDetailsFn}
          />
        </div>
      )
    }
  }

  render() {
    return <div>{this.pickContent()}</div>
  }
}

const APIsProtocols = () => (
  <section className="apisAndProtocols" id="apis-and-protocols">
    <Container fluid={true}>
      <Row>
        <Col>
          <ContentPicker />
        </Col>
      </Row>
    </Container>
  </section>
)

export default APIsProtocols
