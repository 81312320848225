/* eslint-disable */
export default {
  id: "solace-javascript-api",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace JavaScript API",
  protocol: {
    id: "web-messaging",
    name: "Web Messaging",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "JavaScript",
    logo: "./images/javascript-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/javascript/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/js/index.html",
    download: [
      {
        name: "Solace Developer Portal",
        url: "https://products.solace.com/download/JS_API",
      },
    ],
  },
  step1: {
    name: "JavaScript API",
    getAPI: [
      {
        title: "Using NPM",
        code: ["npm install solclientjs"],
      },
      {
        title: "Using the Solace Developer Portal",
        description:
          'The Solace Web Messaging API for JavaScript can be downloaded <a href="https://solace.com/downloads/?fwp_downloads=solace-apis/" target="_blank">here</a>.',
      },
      {
        title: "Using WebPack and exports-loader, in your webpack.config.js",
        code: [
          "const path = require('path'); <br>\
                const solclientjs = path.resolve(__dirname, 'path/to/solclientjs-10.0.0/lib/solclient.js'); <br>\
                module.exports = { <br>\
                  &nbsp&nbsp resolve: { <br>\
                    &nbsp&nbsp&nbsp&nbsp alias: { <br>\
                      &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp solclientjs$: solclientjs <br>\
                    &nbsp&nbsp&nbsp&nbsp } <br>\
                  &nbsp&nbsp }, <br>\
                  &nbsp&nbsp module: { <br>\
                    &nbsp&nbsp&nbsp&nbsp rules: [ <br>\
                      &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp { <br>\
                        &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp test: require.resolve(solclientjs), <br>\
                        &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp use: 'exports-loader?window.solace' <br>\
                      &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp } <br>\
                    &nbsp&nbsp&nbsp&nbsp ] <br>\
                  &nbsp&nbsp } <br>\
                }",
        ],
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must connect a Solace session.",
      "The Solace session is the basis for all client communication with the Solace PubSub+ message broker.",
    ],
    code: [
      {
        protocol: "Web Messaging",
        sample:
          ' \
                        var session = solace.SolclientFactory.createSession({ <br>\
                        &nbsp;&nbsp; url: "<span class="value">${url}</span>", <br>\
                        &nbsp;&nbsp; vpnName: "<span class="value">${vpn}</span>", <br>\
                        &nbsp;&nbsp; userName: "<span class="value">${username}</span>", <br>\
                        &nbsp;&nbsp; password: "<span class="value">${password}</span>", <br>\
                        }); <br>\
                        try { <br>\
                            &nbsp;&nbsp; session.connect(); <br>\
                        } catch (error) { <br>\
                            &nbsp;&nbsp; console.log(error); <br>\
                        }',
      },
      {
        protocol: "Secured Web Messaging",
        sample:
          ' \
                var session = solace.SolclientFactory.createSession({ <br>\
                &nbsp;&nbsp; url: "<span class="value">${url}</span>", <br>\
                &nbsp;&nbsp; vpnName: "<span class="value">${vpn}</span>", <br>\
                &nbsp;&nbsp; userName: "<span class="value">${username}</span>", <br>\
                &nbsp;&nbsp; password: "<span class="value">${password}</span>", <br>\
                }); <br>\
                try { <br>\
                    &nbsp;&nbsp; session.connect(); <br>\
                } catch (error) { <br>\
                    &nbsp;&nbsp; console.log(error); <br>\
                }',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://tutorials.solace.dev/javascript/publish-subscribe/",
      },
      {
        id: "persistence-queues",
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url:
          "https://tutorials.solace.dev/javascript/persistence-with-queues/",
      },
      {
        id: "request-reply",
        name: "Request/ <br>\
                        Reply",
        logo: "./images/request-reply.svg",
        url:
          "https://tutorials.solace.dev/javascript/request-reply/",
      },
      {
        id: "confirmed-delivery",
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url:
          "https://tutorials.solace.dev/javascript/confirmed-delivery/",
      },
      {
        id: "topic-mapping",
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url:
          "https://tutorials.solace.dev/javascript/topic-to-queue-mapping/",
      },
    ],
  },
}
