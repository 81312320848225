import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

// Import Components
import Header from "./header/header"
import CodeSamples from "./code-samples/code-samples"
import SecondaryNav from "./nav-secondary"
import Intro from "./intro"
import EventPortal from "./event-portal"
import APIsProtocols from "./apis-protocols/apis-protocols"
import PlatformAPIs from "./platformAPIs"
// import UseCases from "./use-cases/use-cases"
import Resources from "./resources/resources"
import Footer from "./footer"

// Imports CSS
import "../css/main.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
        </div>
        <CodeSamples />
        <SecondaryNav />
        <Intro />
        <EventPortal />
        <APIsProtocols />
        <PlatformAPIs />
        <Resources />
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
