import React from "react"
import { Container, Jumbotron } from "reactstrap"
import content from "../../data/content"

const Hero = props => {
  return (
    <Jumbotron className="jumbotron-header">
      <Container fluid={true}>
        <h1 className="display-2">{content.hero.header}</h1>
        <p className="lead">{content.hero.plead}</p>
        <p className="ctas">
          <a
            className="button"
            href="https://solace.com/products/event-broker/software/getting-started"
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.hero.ctadocker}
          </a>
          <a
            className="button"
            href="https://console.solace.cloud/login/new-account"
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.hero.ctacloud}
          </a>
        </p>
      </Container>
    </Jumbotron>
  )
}

export default Hero
