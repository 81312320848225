/* eslint-disable */
export default {
  id: "pahoPythonAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Eclipse Paho Python Client",
  protocol: {
    id: "mqtt",
    name: "MQTT",
    logo: "./images/paho.png",
    usesVPN: false,
  },
  language: {
    name: "Python",
    logo: "./images/python-logo.png",
  },
  links: {
    api: "https://www.eclipse.org/paho/clients/python/docs/",
    download: [
      {
        name: "Eclipse Paho Portal",
        url: "https://www.eclipse.org/paho/clients/python/",
      },
    ],
  },
  step1: {
    name: "Eclipse Paho Python Client",
    getAPI: [
      {
        title: "Download from Eclipse",
        description:
          'Get Eclipse Paho Python Client from <a href="https://www.eclipse.org/paho/clients/python/" target="_bank">here</a>',
      },
    ],
  },
}
