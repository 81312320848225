import solaceJMSAPI from "./client-libraries/solaceJMSAPI"
import solaceJavascriptAPI from "./client-libraries/solaceJavascriptAPI"
import solaceNodeJSAPI from "./client-libraries/solaceNodeJSAPI"
import pahoJavaAPI from "./client-libraries/pahoJavaAPI"
import solaceJavaRTOAPI from "./client-libraries/solaceJavaRTOAPI"
import solaceCAPI from "./client-libraries/solaceCAPI"
import solacePythonAPI from "./client-libraries/solacePythonAPI"
import solaceGoAPI from "./client-libraries/solaceGoAPI"
import solaceDotNetAPI from "./client-libraries/solaceDotNetAPI"
import solaceQpid1API from "./client-libraries/solaceQpid1API"
import solaceQpid2API from "./client-libraries/solaceQpid2API"
import solaceJCSMPAPI from "./client-libraries/solaceJCSMPAPI"
import solaceJavaAPI from "./client-libraries/solaceJavaAPI"
import solaceAmqp10API from "./client-libraries/solaceAmqp10API"
import restAPI from "./client-libraries/restAPI"
import pahoJavaScriptAPI from "./client-libraries/pahoJavaScriptAPI"
import pahoCAPI from "./client-libraries/pahoCAPI"
import pahoCplusplusAPI from "./client-libraries/pahoCplusplusAPI"
import pahoDotNetAPI from "./client-libraries/pahoDotNetAPI"
import pahoPythonAPI from "./client-libraries/pahoPythonAPI"
import springCloudStreamsAPI from "./client-libraries/springCloudStreamsAPI"
import springBootJavaAPI from "./client-libraries/springBootJavaAPI"
import springBootJmsAPI from "./client-libraries/springBootJmsAPI"

const libraries = {
  solaceJMSAPI: solaceJMSAPI,
  solaceJavascriptAPI: solaceJavascriptAPI,
  solaceNodeJSAPI: solaceNodeJSAPI,
  pahoJavaAPI: pahoJavaAPI,
  solaceJavaRTOAPI: solaceJavaRTOAPI,
  solaceCAPI: solaceCAPI,
  solaceDotNetAPI: solaceDotNetAPI,
  solacePythonAPI: solacePythonAPI,
  solaceGoAPI: solaceGoAPI,
  solaceQpid1API: solaceQpid1API,
  solaceQpid2API: solaceQpid2API,
  solaceJCSMPAPI: solaceJCSMPAPI,
  solaceJavaAPI: solaceJavaAPI,
  solaceAmqp10API: solaceAmqp10API,
  restAPI: restAPI,
  pahoJavaScriptAPI: pahoJavaScriptAPI,
  pahoCAPI: pahoCAPI,
  pahoCplusplusAPI: pahoCplusplusAPI,
  pahoDotNetAPI: pahoDotNetAPI,
  pahoPythonAPI: pahoPythonAPI,
  springCloudStreamsAPI: springCloudStreamsAPI,
  springBootJavaAPI: springBootJavaAPI,
  springBootJmsAPI: springBootJmsAPI,
}

export default function getClientLibrary(id) {
  return libraries[id]
}
