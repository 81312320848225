import React from "react"
import "../../css/connectivity-details-v1.css"
import getClientLibrary from "./client-library-manager.js"
import DetailsArea from "./details-area.js"
import protocols from "./protocols.js"

class ConnectionDetails extends React.Component {
  constructor(props) {
    super(props)
    this.selectedLibrary = getClientLibrary(props.libraryID)
    this.changeData = this.changeData.bind(this)
    this.toggleConnectDetailsFn = props.toggleConnectDetailsFn
    this.selectedLibrary.connectionDetail = protocols.find(
      protocol => protocol.id === this.selectedLibrary.protocol.id
    )
    if (!this.selectedLibrary.connectionDetail) {
      // Our library doesn't have a protocol defined in protocols.js
      this.selectedLibrary.connectionDetail = this.selectedLibrary.protocol.id
    }
    this.groupedDownload = []
    const groups = [
      ...new Set(this.selectedLibrary.links.download.map(item => item.group)),
    ]	
    groups.forEach(group => {
      var download = {
        groupName: group,
        downloads: this.selectedLibrary.links.download.filter(function(
          download
        ) {
          return download.group === group
        }),
      }
      this.groupedDownload.push(download)
    })
    this.state = {
      step: 1,
      username: "",
      password: "",
      url: "",
      vpn: "",
    }
  }

  renderMultipleDownloadLinks(links) {
    if (links.length > 1) {
      return (
        <div className="icon">
          <i className="fa fa-download icon-content" />
          <select value="" onChange={event => this.navigateToLink(event)}>
            <option disabled style={{ display: "none" }}></option>
            {links.map(item => {
              return (
                <option
                  className="item"
                  value={item.url}
                  key={item.name}
                  id={
                    "download-" +
                    this.selectedLibrary.id +
                    "-" +
                    this.selectedLibrary.connectionDetail.id +
                    "-" +
                    item.name
                  }
                >
                  {item.name}
                </option>
              )
            })}
          </select>
        </div>
      )
    }
  }
  navigateToLink(event) {
    window.open(event.currentTarget.value, "_blank").focus()
  }

  chooseStep(stepInteger, event) {
    event.preventDefault() //Prevent page from refreshing on click
    let topOfSection = document.getElementById("apis-and-protocols")
    if (topOfSection && topOfSection.scrollIntoView) {
      topOfSection.scrollIntoView({ behavior: "smooth" })
    }
    let state = { step: stepInteger }
    this.setState(state)
  }

  changeData(key, value) {
    switch (key) {
      case "username":
        value === "" ? this.setState(state => ({ username: "default" })) : this.setState(state => ({ username: value }))
        break
      case "password":
        value === "" ? this.setState(state => ({ password: "default" })) : this.setState(state => ({ password: value }))
        break
      case "url":
        value === "" ? this.setState(state => ({ url: "localhost" })) : this.setState(state => ({ url: value }))
        break
      case "vpn":
        value === "" ? this.setState(state => ({ vpn: "default" })) : this.setState(state => ({ vpn: value }))
        break
      default:
        return "Unknown key"
    }
  }

  render() {
    return (
      <div className="connectivity-detail">
        <button className="exit" onClick={() => this.toggleConnectDetailsFn()}>
          Select another API
        </button>
        <div className="card">
          <div className="header">
            <div className="language">
              <label>{this.selectedLibrary.language.name}</label>
            </div>
            <div className="logos">
              <img
                src={require("" + this.selectedLibrary.protocol.logo)}
                alt=""
              ></img>
            </div>
            <div className="links">
              {this.selectedLibrary.links.api ? (
                <div className="link">
                  <label>API Docs</label>
                  <a
                    className="icon"
                    href={this.selectedLibrary.links.api}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-book"></i>
                  </a>
                </div>
              ) : (
                ""
              )}
              {this.selectedLibrary.links.tutorial ? (
                <div className="link">
                  <label>Tutorials</label>
                  <a
                    className="icon"
                    href={this.selectedLibrary.links.tutorial}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &lt;&gt;
                  </a>
                </div>
              ) : (
                ""
              )}
              {this.selectedLibrary.links.download.length > 0 ? (
                <div className="link">
                  <label>Download</label>
                  {this.selectedLibrary.links.download.length === 1 ? (
                    <a
                      className="icon"
                      href={this.selectedLibrary.links.download[0].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      id={
                        "download-" +
                        this.selectedLibrary.id +
                        "-" +
                        this.selectedLibrary.connectionDetail.id
                      }
                    >
                      <i className="fa fa-download"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {this.renderMultipleDownloadLinks(
                    this.selectedLibrary.links.download
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="title">{this.selectedLibrary.displayName}</div>
          </div>

          <div className="content">
            <div className="details">
              <div className="tabs">
                <ul style={{ overflow: "hidden", display: "flex" }}>
                  <li className="tab">
                    <button id="get-api" onClick={e => this.chooseStep(1, e)}>
                      <div
                        className={
                          this.state.step === 1 ? "active" : "tab-number"
                        }
                      >
                        1
                      </div>
                      <label
                        className={this.state.step === 1 ? "active-label" : ""}
                      >
                        Get API
                      </label>
                    </button>
                  </li>
                  <li className="tab">
                    <button
                      id="connect-to-service"
                      onClick={e => this.chooseStep(2, e)}
                    >
                      <div
                        className={
                          this.state.step === 2 ? "active" : "tab-number"
                        }
                      >
                        2
                      </div>
                      <label
                        className={this.state.step === 2 ? "active-label" : ""}
                      >
                        Connect
                        <br />
                        to Service
                      </label>
                    </button>
                  </li>
                  <li className="tab">
                    <button
                      id="learn-more"
                      onClick={e => this.chooseStep(3, e)}
                    >
                      <div
                        className={
                          this.state.step === 3 ? "active" : "tab-number"
                        }
                      >
                        3
                      </div>
                      <label
                        className={this.state.step === 3 ? "active-label" : ""}
                      >
                        Learn More
                      </label>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <DetailsArea
                  step={this.state.step}
                  selectedLibrary={this.selectedLibrary}
                  username={this.state.username}
                  password={this.state.password}
                  url={this.state.url}
                  vpn={this.state.vpn}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    marginRight: "auto",
                  }}
                >
                  {this.state.step === 3 && this.selectedLibrary.links.api ? (
                    <div>
                      <div className="title">API Docs</div>
                      <div className="description">
                        {this.selectedLibrary.id === "springCloudStreams"
                          ? "See Solace Binder Configuration Options "
                          : "See the API docs "}
                        <a
                          href={this.selectedLibrary.links.api}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                        .
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.step === 3 &&
                  this.selectedLibrary.links.download.length > 0 ? (
                    <div>
                      <div className="title">API Download</div>
                      {this.selectedLibrary.links.download.length === 1 ? (
                        <div className="description">
                          {this.selectedLibrary.id === "springCloudStreams" ||
                          this.selectedLibrary.id === "springBootJavaAPI" ||
                          this.selectedLibrary.id === "springBootJmsAPI"
                            ? "Get the "
                            : "Download the API from "}
                          <a
                            href={this.selectedLibrary.links.download[0].url}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={
                              "download-" +
                              this.selectedLibrary.id +
                              "-" +
                              this.selectedLibrary.connectionDetail.id
                            }
                          >
                            {this.selectedLibrary.links.download[0].name}
                          </a>
                          .
                        </div>
                      ) : (
                        <div>
                          {this.groupedDownload.map(group => {
                            return (
                              <div
                                key={group.groupName}
                                className="api-download"
                              >
                                {group.groupName}
                                <div className="downloads">
                                  {group.downloads.map(download => {
                                    return (
                                      <div
                                        key={download.name}
                                        className="download"
                                      >
                                        <a
                                          href={download.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          id={
                                            "download-" +
                                            this.selectedLibrary.id +
                                            "-" +
                                            this.selectedLibrary
                                              .connectionDetail.id +
                                            "-" +
                                            download.name
                                          }
                                        >
                                          {download.name}
                                        </a>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {this.state.step === 2 ? (
              <div className="credentials">
                <div className="title">Connection Details</div>
                <div className="information-box">
                  <div className="credential">
                    <label>Endpoint URI</label>
                    <div>
                      <input
                        onChange={event => {
                          this.changeData("url", event.currentTarget.value)
                        }}
                      />
                    </div>
                  </div>
                  {this.selectedLibrary.protocol.usesVPN ? (
                    <div className="credential">
                      <label>Message VPN</label>
                      <div>
                        <input
                          onChange={event => {
                            this.changeData("vpn", event.currentTarget.value)
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="credential">
                    <label>Username</label>
                    <div>
                      <input
                        onChange={event => {
                          this.changeData("username", event.currentTarget.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="credential">
                    <label>Password</label>
                    <div>
                      <input
                        onChange={event => {
                          this.changeData("password", event.currentTarget.value)
                        }}
                      />
                    </div>
                  </div>
                  
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {this.state.step === 1 ? (
            <div className="nav-buttons" style={{ justifyContent: "flex-end" }}>
              <div className="nav">
                <button onClick={e => this.chooseStep(2, e)}>Next</button>
                <label>Connect to Service</label>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.step === 2 ? (
            <div className="nav-buttons">
              <div className="nav">
                <button onClick={e => this.chooseStep(1, e)}>Previous</button>
                <label>Get API</label>
              </div>
              <div className="nav next">
                <button onClick={e => this.chooseStep(3, e)}>Next</button>
                <label>Learn More</label>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.step === 3 ? (
            <div className="nav-buttons">
              <div className="nav">
                <button onClick={e => this.chooseStep(2, e)}>Previous</button>
                <label>Connect to Service</label>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }
}

export default ConnectionDetails
