const protocols = [
  {
    name: "SMF",
    id: "smf",
    displayName: "Solace Messaging",
    description:
      "Start messaging with client libraries that use the Solace Message Format (SMF) protocol over TCP.",
    logo: "./images/solace-logo.png",
    showVpn: true,
    logoHeight: "40",
    libraries: [
      {
        id: "solaceJavaAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solaceJCSMPAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solaceJavaRTOAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solaceJMSAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solaceCAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solacePythonAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solaceGoAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solaceDotNetAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "springBootJavaAPI",
        logo: "./images/spring-boot-logo.png",
        protocol: "SMF",
        displayName: "Spring Boot Java API",
      },
      {
        id: "springBootJmsAPI",
        logo: "./images/spring-boot-logo.png",
        protocol: "SMF",
        displayName: "Spring Boot JMS API",
      }, 
      {
        id: "springCloudStreamsAPI",
        logo: "./images/spring-cloud-logo.png",
        protocol: "SMF",
        displayName: "Spring Cloud Stream",
      },
    ],
  },
  {
    name: "Web Messaging",
    id: "web-messaging",
    displayName: "Solace Web Messaging",
    description:
      "Start messaging with client libraries that use the Solace Message Format (SMF) protocol over Web Sockets or HTTP.",
    logo: "./images/solace-logo.png",
    showVpn: true,
    logoHeight: "40",
    libraries: [
      {
        id: "solaceJavascriptAPI",
        logo: "./images/solace-logo.png",
      },
      {
        id: "solaceNodeJSAPI",
        logo: "./images/solace-logo.png",
      },
    ],
  },
  {
    name: "AMQP",
    id: "amqp",
    displayName: "AMQP",
    description: "Start messaging with open APIs that use the AMQP.",
    logo: "./images/amqp.png",
    showVpn: false,
    logoHeight: "35",
    libraries: [
      {
        id: "solaceQpid1API",
        logo: "./images/qpid.png",
      },
      {
        id: "solaceQpid2API",
        logo: "./images/qpid.png",
      },
      {
        id: "solaceAmqp10API",
        logo: "./images/amqp.png",
      },
    ],
  },
  {
    name: "MQTT",
    id: "mqtt",
    displayName: "MQTT",
    description: "Start messaging with open APIs that use the MQTT protocol.",
    logo: "./images/mqtt.png",
    showVpn: false,
    logoHeight: "33",
    libraries: [
      {
        id: "pahoJavaAPI",
        logo: "./images/java-logo.png",
      },
      {
        id: "pahoJavaScriptAPI",
        logo: "./images/javascript-logo.png",
      },
      {
        id: "pahoCAPI",
        logo: "./images/paho.png",
      },
      {
        id: "pahoCplusplusAPI",
        logo: "./images/c-logo.png",
      },
      {
        id: "pahoDotNetAPI",
        logo: "./images/paho.png",
      },
      {
        id: "pahoPythonAPI",
        logo: "./images/paho.png",
      },
    ],
  },
 {
    name: "REST",
    id: "rest",
    displayName: "REST",
    description: "Start messaging with the Solace Messaging REST API.",
    logo: "./images/rest.png",
    showVpn: false,
    logoHeight: "35",
    libraries: [
      {
        id: "restAPI",
        logo: "./images/rest.png",
      },
    ],
  },
]

export default protocols
