/* eslint-disable */
export default {
  id: "solace-node-api",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace Node.js API",
  protocol: {
    id: "web-messaging",
    name: "Web Messaging",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Node.js",
    logo: "./images/nodejs-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/nodejs/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/nodejs/index.html",
    download: [
      {
        name: "Solace Developer Portal",
        url: "https://products.solace.com/download/NODE_JS_API",
      },
    ],
  },
  step1: {
    name: "Node.js API",
    getAPI: [
      {
        title: "Using NPM",
        code: ["npm install solclientjs"],
      },
      {
        title: "Using the Solace Developer Portal",
        description:
          'The Solace Web Messaging API for Node.js can be downloaded <a href="https://solace.com/downloads/?fwp_downloads=solace-apis/" target="_blank">here</a>.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must connect a Solace session.",
      "The Solace session is the basis for all client communication with the Solace PubSub+ message broker.",
    ],
    code: [
      {
        protocol: "Web Messaging",
        sample:
          ' \
               var session = solace.SolclientFactory.createSession({ <br>\
               &nbsp;&nbsp; url: "<span class="value">${url}</span>", <br>\
               &nbsp;&nbsp; vpnName: "<span class="value">${vpn}</span>", <br>\
               &nbsp;&nbsp; userName: "<span class="value">${username}</span>", <br>\
               &nbsp;&nbsp; password: "<span class="value">${password}</span>", <br>\
               }); <br>\
               try { <br>\
                   &nbsp;&nbsp; session.connect(); <br>\
               } catch (error) { <br>\
                   &nbsp;&nbsp; console.log(error); <br>\
               }',
      },
      {
        protocol: "Secured Web Messaging",
        sample:
          ' \
               var session = solace.SolclientFactory.createSession({ <br>\
               &nbsp;&nbsp; url: "<span class="value">${url}</span>", <br>\
               &nbsp;&nbsp; vpnName: "<span class="value">${vpn}</span>", <br>\
               &nbsp;&nbsp; userName: "<span class="value">${username}</span>", <br>\
               &nbsp;&nbsp; password: "<span class="value">${password}</span>", <br>\
               }); <br>\
               try { <br>\
                   &nbsp;&nbsp; session.connect(); <br>\
               } catch (error) { <br>\
                   &nbsp;&nbsp; console.log(error); <br>\
               }',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://tutorials.solace.dev/nodejs/publish-subscribe/",
      },
      {
        id: "persistence-queues",
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url:
          "https://tutorials.solace.dev/nodejs/persistence-with-queues/",
      },
      {
        id: "request-reply",
        name: "Request/ <br>\
                        Reply",
        logo: "./images/request-reply.svg",
        url: "https://tutorials.solace.dev/nodejs/request-reply/",
      },
      {
        id: "confirmed-delivery",
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url:
          "https://tutorials.solace.dev/nodejs/confirmed-delivery/",
      },
      {
        id: "topic-mapping",
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url:
          "https://tutorials.solace.dev/nodejs/topic-to-queue-mapping/",
      },
    ],
  },
}
