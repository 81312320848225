const languages = [
  {
    displayName: "Connect with Java",
    description:
      "Solace JCSMP, Solace Java, Solace JMS over SMF, Paho over MQTT, QPID JMS 1.1 over AMQP, QPID JMS 2.0 over AMQP",
    id: "java",
    libraries: [
      {
        id: "solaceJavaAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace Java API",
      },
      {
        id: "solaceJCSMPAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace JCSMP API",
      },
      {
        id: "solaceJavaRTOAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace JavaRTO",
      },
      {
        id: "solaceJMSAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace JMS API",
      },
      {
        id: "restAPI",
        logo: "./images/solace-logo.png",
        protocol: "REST",
        displayName: "Solace REST Messaging API",
      },
      {
        id: "pahoJavaAPI",
        logo: "./images/paho.png",
        protocol: "MQTT",
        displayName: "Eclipse Paho Java Client",
      },
      {
        id: "solaceQpid1API",
        logo: "./images/qpid.png",
        protocol: "AMQP",
        displayName: "QPID JMS 1.1",
      },
      {
        id: "solaceQpid2API",
        logo: "./images/qpid.png",
        protocol: "AMQP",
        displayName: "QPID JMS 2.0",
      },
    ],
    logo: "./images/java-logo.png",
    logoHeight: "60",
  },
  {
    description: "Solace C API over SMF, Paho over MQTT",
    displayName: "Connect with C and C++",
    id: "c-and-c-plus-plus",
    libraries: [
      {
        id: "solaceCAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace C",
      },
      {
        id: "restAPI",
        logo: "./images/solace-logo.png",
        protocol: "REST",
        displayName: "Solace REST Messaging API",
      },
      {
        id: "pahoCAPI",
        logo: "./images/paho.png",
        protocol: "MQTT",
        displayName: "Eclipse Paho C Client",
      },
      {
        id: "pahoCplusplusAPI",
        logo: "./images/paho.png",
        protocol: "MQTT",
        displayName: "Eclipse Paho C++ Client",
      },
    ],
    logo: "./images/c-logo.png",
    logoHeight: "60",
  },
  {
    description: "Solace Python API over SMF, Paho over MQTT",
    displayName: "Connect with Python",
    id: "python",
    libraries: [
      {
        id: "solacePythonAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace Python API",
      },
      {
        id: "restAPI",
        logo: "./images/solace-logo.png",
        protocol: "REST",
        displayName: "Solace REST Messaging API",
      },
      {
        id: "pahoPythonAPI",
        logo: "./images/paho.png",
        protocol: "MQTT",
        displayName: "Eclipse Paho Python Client",
      },
    ],
    logo: "./images/python-logo.png",
    logoHeight: "50",
  },
  {
    description: "Solace Go API over SMF",
    displayName: "Connect with Go",
    id: "go",
    libraries: [
      {
        id: "solaceGoAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace Go API",
      },
    ],
    logo: "./images/go-logo.png",
    logoHeight: "50",
  },
  {
    displayName: "Connect with JavaScript",
    description: "Solace Javascript API over SMF, Paho over MQTT",
    id: "javascript",
    libraries: [
      {
        id: "solaceJavascriptAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace JavaScript API",
      },
      {
        id: "restAPI",
        logo: "./images/solace-logo.png",
        protocol: "REST",
        displayName: "Solace REST Messaging API",
      },
      {
        id: "pahoJavaScriptAPI",
        logo: "./images/paho.png",
        protocol: "MQTT",
        displayName: "Eclipse Paho JavaScript Client",
      },
    ],
    logo: "./images/javascript-logo.png",
    logoHeight: "60",
  },
  {
    description: "Solace Node.js API over SMF, AMPQP10 Open Source over AMQP",
    displayName: "Connect with Node.js",
    id: "nodejs",
    libraries: [
      {
        id: "solaceNodeJSAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace Node.js API",
      },
      {
        id: "restAPI",
        logo: "./images/solace-logo.png",
        protocol: "REST",
        displayName: "Solace REST Messaging API",
      },
      {
        id: "solaceAmqp10API",
        logo: "./images/amqp.png",
        protocol: "AMQP",
        displayName: "AMQP10 Open Source API",
      },
    ],
    logo: "./images/nodejs-logo.png",
    logoHeight: "60",
  },
  {
    description: "Solace .NET API over SMF, Paho over MQTT",
    displayName: "Connect with .NET",
    id: "dotnet",
    libraries: [
      {
        id: "solaceDotNetAPI",
        logo: "./images/solace-logo.png",
        protocol: "SMF",
        displayName: "Solace .NET",
      },
      {
        id: "restAPI",
        logo: "./images/solace-logo.png",
        protocol: "REST",
        displayName: "Solace REST Messaging API",
      },
      {
        id: "pahoDotNetAPI",
        logo: "./images/paho.png",
        protocol: "MQTT",
        displayName: "Eclipse Paho .NET Client",
      },
    ],
    logo: "./images/dotnet-logo.png",
    logoHeight: "60",
  },
  {
    displayName: "Connect with Spring",
    description: "Spring Cloud Stream, Spring Boot",
    id: "spring",
    libraries: [
      {
        id: "springCloudStreamsAPI",
        logo: "./images/spring-cloud-logo.png",
        protocol: "SMF",
        displayName: "Spring Cloud Stream",
      },
      {
        id: "springBootJavaAPI",
        logo: "./images/spring-boot-logo.png",
        protocol: "SMF",
        displayName: "Spring Boot Java API",
      },
      {
        id: "springBootJmsAPI",
        logo: "./images/spring-boot-logo.png",
        protocol: "SMF",
        displayName: "Spring Boot JMS API",
      },
    ],
    logo: "./images/spring-logo.png",
    logoHeight: "40",
  },
]

export default languages
