import React from "react"
import { Container, Row, Col } from "reactstrap"
import OpenAPIlogo from "../images/openapi-logo-trans-bg.png"

const PlatformAPIs = props => {
  return (
    <section className="LightGrey border-top" id="PlatformAPIs">
      <Container fluid={true}>
        <Row className="pb-4">
          <Col xs="12" md="12" lg="12">
            <h2>PubSub+ Platform APIs</h2>
            <p>
              The PubSub+ Platform APIs are RESTful APIs that enable an API
              first approach to designing, managing, developing, and operating
              your Event-Driven Architecture while using PubSub+.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="6" className="apiBox">
            <Row>
              <Col xs="12" md="12" lg="12">
                <h3 className="font-weight-bold">PubSub+ Cloud REST APIs</h3>
                <p className="pb-0 mb-0">
                  Work programmatically with PubSub+ Cloud.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12" lg="6">
                <div className="card card-body h-100">
                  <div className="card-title border-bottom border-secondary h4 font-weight-bold text-dark p-2">
                    APIs
                  </div>
                  <ul className="ml-0">
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/getclientprofile"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Mission Control
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/getenums"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Event Portal - Designer
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/geteventmeshes"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Event Portal - Runtime
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/searchenvironments"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Platform
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/getsummaryusage"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Billing
                      </a>
                    </li>
                  </ul>
                  <div>
                    <a
                      href="https://api.solace.dev/cloud/page/openapi-specifications"
                      className="buttonSmall"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get OpenAPI Specs
                    </a>
                    <a href="https://www.openapis.org/" target="_blank">
                      <img
                        src={OpenAPIlogo}
                        alt="OpenAPI Logo"
                        className="openapiLogo float-right"
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="12" lg="6">
                <div className="card card-body h-100">
                  <div className="card-title border-bottom border-secondary h4 font-weight-bold text-dark p-2">
                    Getting Started
                  </div>
                  <ul className="ml-0">
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/recipes"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Recipes
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/authentication"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Authentication / API Token Creation
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/error-handling"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Error Handling
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/versioning"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Versioning
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/pagination"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pagination
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://api.solace.dev/cloud/reference/filtering"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Filtering
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="12" lg="6" className="apiBox">
            <Row>
              <Col xs="12" md="12" lg="12">
                <h3 className="font-weight-bold">
                  SEMP (Solace Element Management Protocol)
                </h3>
                <p className="pb-0 mb-0">
                  Configure, Monitor, or take Action on the PubSub+ Event
                  Brokers.
                </p>
              </Col>
              <Col xs="12" md="12" lg="6">
                <div className="card card-body h-100">
                  <div className="card-title border-bottom border-secondary h4 font-weight-bold text-dark p-2">
                    APIs
                  </div>
                  <ul className="ml-0">
                    <li>
                      <a
                        href="https://docs.solace.com/API-Developer-Online-Ref-Documentation/swagger-ui/software-broker/config/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Config
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.solace.com/API-Developer-Online-Ref-Documentation/swagger-ui/software-broker/action/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Action
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.solace.com/API-Developer-Online-Ref-Documentation/swagger-ui/software-broker/monitor/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Monitor
                      </a>
                    </li>
                  </ul>
                  <div>
                    <a
                      href="https://solace.community/discussion/1034/sempv2-your-solace-broker-comes-with-the-openapi-docs"
                      className="buttonSmall"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get OpenAPI Specs
                    </a>
                    <a href="https://www.openapis.org/" target="_blank">
                      <img
                        src={OpenAPIlogo}
                        alt="OpenAPI Logo"
                        className="openapiLogo float-right"
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="12" lg="6">
                <div className="card card-body h-100">
                  <div className="card-title border-bottom border-secondary h4 font-weight-bold text-dark p-2">
                    Getting Started
                  </div>
                  <ul className="ml-0">
                    <li>
                      <a
                        href="https://tutorials.solace.dev/semp/curl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Basic Operations with curl
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.solace.com/Admin/SEMP/SEMP-API-Archit.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Architecture
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.solace.com/Admin/SEMP/SEMP-Error-Handling.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Error Handling
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.solace.com/Admin/SEMP/Using-Legacy-SEMP.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Legacy SEMP
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PlatformAPIs
