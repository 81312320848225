import React from "react"
import { Container, Row, Col } from "reactstrap"
import CodeSamplesPub from "./code-samples-pub"
import CodeSamplesSub from "./code-samples-sub"
import PubSubAnimationHorizontal from "../../images/code-samples/pubsub-animation-dark-horizontal.gif"

const CodeSamples = props => {
  return (
    <section id="CodeSamples" className="code-samples DarkGrey">
      <Container fluid={true}>
        <Row>
          <Col md="12" xl="5">
            <CodeSamplesPub />
          </Col>
          <Col md="12" xl="2" className="align-self-center">
            <img
              className="mx-auto d-block PubSubAnimationHorizontal"
              src={PubSubAnimationHorizontal}
              alt="PubSub+"
            />
          </Col>
          <Col md="12" xl="5">
            <CodeSamplesSub />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CodeSamples
