/* eslint-disable */
export default {
  id: "springCloudStreams",
  componentName: "connectivity-detail-template-v1",
  displayName: "Spring Cloud Stream",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/spring-cloud-logo.png",
    usesVPN: true,
  },
  language: {
    name: "Spring",
    logo: "./images/spring-cloud-logo.png",
  },
  links: {
    tutorial:
      "https://tutorials.solace.dev/spring/spring-cloud-stream/",
    api: "https://github.com/SolaceProducts/solace-spring-cloud/tree/master/solace-spring-cloud-starters/solace-spring-cloud-stream-starter",
    download: [
      {
        name: "Solace Starter",
        url:
          "https://mvnrepository.com/artifact/com.solace.spring.cloud/spring-cloud-starter-stream-solace",
      },
    ],
  },
  step1: {
    name: "Generate your project skeleton",
    getAPI: [
      {
        title: "OPTION 1: I already have a Maven Spring Boot Project",
        description: "Add the dependency below to your Maven POM file.",
        code: [
          "\
&lt;dependency&gt; <br/> \
  &nbsp;&nbsp;&lt;groupId&gt;com.solace.spring.cloud&lt;/groupId&gt; <br/> \
  &nbsp;&nbsp;&lt;artifactId&gt;spring-cloud-starter-stream-solace&lt;/artifactId&gt; <br/> \
  &nbsp;&nbsp;&lt;version&gt;3.1.0&lt;/version&gt; <br/> \
&lt;/dependency&gt; ",
        ],
      },
      {
        title: "OPTION 2: I already have a Gradle Spring Boot Project",
        description: "Add the dependency below to your build.gradle file.",
        code: [
          "compile group: 'com.solace.spring.cloud', name: 'spring-cloud-starter-stream-solace', version: '3.1.0' ",
        ],
      },
      {
       title: "OPTION 3: I'm using the Event Portal - Take advantage of AsyncAPI Code Generation!",
       description: 'Export an AsyncAPI document from the Event Portal and use the <a href="https://github.com/asyncapi/java-spring-cloud-stream-template" target="_blank">Spring Cloud Stream Template</a> for the AsyncAPI Generator to generate your Cloud Stream project. <br/><br/>More info in <a href="https://solace.com/blog/asyncapi-codegen-microservices-using-spring-cloud-stream" target="_blank">this blog</a> or <a href="https://www.youtube.com/watch?v=QEDL6AqsaJc" target="_blank">this video</a>.'
      },
      {
        title:
          "OPTION 4: Starting from Scratch - Use Spring Initializr to bootstrap my project!",
        description:
          'It is recommended to bootstrap your Spring Boot project using <a href="https://start.spring.io/#!dependencies=solace,cloud-stream" target="_blank">Spring Initializr</a>.  \
          <br/> <br/> Spring Initializr will generate your Spring Boot project skeleton for you based on a few simple choices, \
          such as your preferred language, <br/> Spring Boot version, and the dependencies you need to build your microservice. \
          Make sure to choose the "Cloud Stream" dependency! <br/><br/>Once you bootstrap your Spring Cloud Stream project add the Spring Cloud Starter Stream Solace dependency as shown above.',
      }
    ],
  },
  step2: {
    introduction: [
      'Spring Cloud Stream abstracts the event broker configuration from the java classes. \
        Instead the binders and bindings are configured via external configuration. This is commonly done via an <strong>application.yaml</strong> or <strong>application.properties</strong> file on the classpath.\
        Below is an example of a configured <strong>application.yaml</strong> file. \
        Other options are available <a href="https://docs.spring.io/spring-boot/docs/current/reference/html/boot-features-external-config.html" target="_blank">here</a>.',
    ],
    code: [
      {
        protocol: "Cloud Stream",
        sample: "",
      },
    ],
    conclusion: [""],
  },
  step3: {
    tutorials: [
      {
        id: "tutorial",
        name: "Solace Cloud <br/>Stream Tutorial",
        logo: "./images/spring-cloud.svg",
        url:
          "https://tutorials.solace.dev/spring/spring-cloud-stream/",
      },
      {
        id: "sample-code-1",
        name: "Source <br/>Example",
        logo: "./images/github-logo.png",
        url:
          "https://github.com/SolaceSamples/solace-samples-spring/blob/master/cloud-stream-source/src/main/java/com/solace/samples/spring/scs/FahrenheitTempSource.java",
      },
      {
        id: "sample-code-2",
        name: "Sink <br/>Example",
        logo: "./images/github-logo.png",
        url:
          "https://github.com/SolaceSamples/solace-samples-spring/blob/master/cloud-stream-sink/src/main/java/com/solace/samples/spring/scs/TemperatureSink.java",
      },
      {
        id: "sample-code-3",
        name: "Processor Example",
        logo: "./images/github-logo.png",
        url:
          "https://github.com/SolaceSamples/solace-samples-spring/blob/master/cloud-stream-processor/src/main/java/com/solace/samples/spring/scs/ConvertFtoCProcessor.java",
      },
      {
        id: "sample-code-4",
        name: "Solace Spring Cloud <br/>Github Repo",
        logo: "./images/github-logo.png",
        url:
          "https://github.com/SolaceProducts/solace-spring-cloud",
      },
      {
        id: "sample-code-5",
        name: "SCSt Reference <br/>Guide",
        logo: "./images/spring-cloud.svg",
        url:
          "https://cloud.spring.io/spring-cloud-static/spring-cloud-stream/current/reference/html/spring-cloud-stream.html#spring-cloud-stream-reference",
      },
    ],
  },
}
