import React from "react"
import { Container, Row, Col } from "reactstrap"
import "../css/intro.css"
import content from "../data/content"

const Intro = props => {
  return (
    <section className="intro DarkGrey" id="WhatIsPubSub">
      <Container fluid={true}>
        <Row>
          <Col>
            <h2>{content.intro.title}</h2>
            <p>
              <a
                href="https://solace.com/products/platform/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {content.intro.paragraph_1.p1_link}
              </a>{" "}
              {content.intro.paragraph_1.p1_content}{" "}
              <a
                href="https://solace.com/products/event-broker/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {content.intro.paragraph_1.p1_1_link}
              </a>{" "}
              {content.intro.paragraph_1.p1_1_content}{" "}
              <a
                href="https://solace.com/products/portal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {content.intro.paragraph_1.p1_2_link}
              </a>{" "}
              {content.intro.paragraph_1.p1_2_content}
            </p>
            <p>
              {content.intro.paragraph_2.p2_content}{" "}
              <a
                href="https://solace.community"
                target="_blank"
                rel="noopener noreferrer"
              >
                {content.intro.paragraph_2.p2_link}
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Intro
