import React from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap"

export default class SecondaryNav extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      setFixed: false,
    }
    this.navItems = [
      { title: "What is PubSub+?", scrollToId: "WhatIsPubSub" },
      { title: "PubSub+ Event Portal", scrollToId: "EventPortal" },
      { title: "Messaging APIs", scrollToId: "apis-and-protocols" },
      { title: "Platform APIs", scrollToId: "PlatformAPIs" },
      //{ title: "Use Cases", scrollToId: "UseCases" },
      { title: "Resources", scrollToId: "Resources" },
    ]
  }

  componentDidMount() {
    window.addEventListener("scroll", event => this.listenToScroll(event))
  }

  componentDidUpdate() {
    let secondaryNavPosition = document.getElementById("SecondaryNav").offsetTop
    if (secondaryNavPosition > 0) {
      this.posOfSecondaryNav = secondaryNavPosition
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", event => this.listenToScroll(event))
  }

  listenToScroll(event) {
    this.scrollPosition = event.currentTarget.scrollY
    if (this.scrollPosition > this.posOfSecondaryNav) {
      this.setState({
        setFixed: true,
      })
    } else {
      this.setState({
        setFixed: false,
      })
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  scrollToSection(id) {
    let topOfSection = document.getElementById(id)
    if (topOfSection && topOfSection.scrollIntoView) {
      topOfSection.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  render() {
    return (
      <div>
        <Navbar
          className="secondary-nav"
          id="SecondaryNav"
          style={
            this.scrollPosition > this.posOfSecondaryNav
              ? { top: "0px", position: "fixed", zIndex: "10", width: "100%" }
              : {}
          }
          color="dark"
          expand="md"
        >
          <Container fluid={true}>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mr-auto" navbar>
                {this.navItems.map(navItem => {
                  return (
                    <NavItem key={navItem.title}>
                      <NavLink
                        className="text-nowrap"
                        style={{ color: "#00C895" }}
                        onClick={event =>
                          this.scrollToSection(navItem.scrollToId)
                        }
                      >
                        {navItem.title}
                      </NavLink>
                    </NavItem>
                  )
                })}
              </Nav>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <a
                    className="buttonSmall"
                    href="https://solace.com/try-it-now/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Try Now
                  </a>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <div
          style={
            this.scrollPosition > this.posOfSecondaryNav
              ? { height: "56px" }
              : { display: "none" }
          }
        ></div>
      </div>
    )
  }
}
