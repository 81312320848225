/* eslint-disable */
export default {
  id: "solaceDotNetAPI",
  componentName: "connectivity-detail-template-v1",
  displayName: "Solace .NET",
  protocol: {
    id: "smf",
    name: "SMF",
    logo: "./images/solace-logo.png",
    usesVPN: true,
  },
  language: {
    name: ".NET",
    logo: "./images/dotnet-logo.png",
  },
  links: {
    tutorial: "https://tutorials.solace.dev/dotnet/",
    api:
      "https://docs.solace.com/API-Developer-Online-Ref-Documentation/net/html/98265723-512a-4f99-96e9-ea0d592bcb99.htm",
    download: [
      {
        name: ".NET",
        url:
          "https://www.nuget.org/packages/SolaceSystems.Solclient.Messaging/",
      },
    ],
  },
  step1: {
    name: ".NET API",
    getAPI: [
      {
        title: "Using the Solace Developer Portal",
        description:
          'The .NET API library can be downloaded from the <a href="https://solace.com/downloads/?fwp_downloads=solace-apis/" target="_blank">Solace Developer Portal</a>',
      },
      {
        title: "Using nuget.org",
        description:
          'Use the NuGet console or the NuGet Visual Studio Extension to download the <a href="http://nuget.org/packages/SolaceSystems.Solclient.Messaging/" target="_blank">SolaceSystems.Solclient.Messaging</a> package for your solution and to install it for your project.\
                The package contains the required libraries and brief API documentation. It will automatically copy correct libraries from the package to the target directory at build time, but of course if you compile your program from the command line you would need to refer to the API assemblies and libraries locations explicitly.\
                Notice that in this case both x64 and x86 API assemblies and libraries have the same names.',
      },
    ],
  },
  step2: {
    introduction: [
      "In order to send or receive messages, an application must connect a Solace session.",
      "The Solace session is the basis for all client communication with Solace messaging.",
    ],
    code: [
      {
        protocol: "SMF",
        sample:
          '\
                ContextFactoryProperties cfp = new ContextFactoryProperties() <br>\
                { <br>\
                SolClientLogLevel = SolLogLevel.Warning <br>\
                }; <br>\
                cfp.LogToConsoleError(); <br>\
                ContextFactory.Instance.Init(cfp); <br>\
                <br>\
                SessionProperties sessionProps = new SessionProperties() <br>\
                { <br>\
                    &nbsp; Host = "<span class="value">${url}</span>", <br>\
                    &nbsp; VPNName = "<span class="value">${vpn}</span>", <br>\
                    &nbsp; UserName = "<span class="value">${username}</span>", <br>\
                    &nbsp; Password = "<span class="value">${password}</span>", <br>\
                    &nbsp; ReconnectRetries = DefaultReconnectRetries <br>\
                }; <br>\
                <br>\
                using (IContext context = ContextFactory.Instance.CreateContext(new ContextProperties(), null)) <br>\
                using (ISession session = context.CreateSession(sessionProps, HandleMessage, null)) <br>\
                { <br>\
                    &nbsp; ReturnCode returnCode = session.Connect(); <br>\
                    &nbsp; if (returnCode == ReturnCode.SOLCLIENT_OK) <br>\
                    &nbsp; { <br>\
                    &nbsp; &nbsp; // connected to the Solace message router <br>\
                    &nbsp; } <br>\
                } <br>\
                ',
      },
      {
        protocol: "Secured SMF",
        sample:
          '\
                ContextFactoryProperties cfp = new ContextFactoryProperties() <br>\
                { <br>\
                SolClientLogLevel = SolLogLevel.Warning <br>\
                }; <br>\
                cfp.LogToConsoleError(); <br>\
                ContextFactory.Instance.Init(cfp); <br>\
                <br>\
                SessionProperties sessionProps = new SessionProperties() <br>\
                { <br>\
                    &nbsp; Host = "<span class="value">${url}</span>", <br>\
                    &nbsp; VPNName = "<span class="value">${vpn}</span>", <br>\
                    &nbsp; UserName = "<span class="value">${username}</span>", <br>\
                    &nbsp; Password = <span class="value">${password}</span>", <br>\
                    &nbsp; ReconnectRetries = DefaultReconnectRetries <br>\
                }; <br>\
                <br>\
                using (IContext context = ContextFactory.Instance.CreateContext(new ContextProperties(), null)) <br>\
                using (ISession session = context.CreateSession(sessionProps, HandleMessage, null)) <br>\
                { <br>\
                    &nbsp; ReturnCode returnCode = session.Connect(); <br>\
                    &nbsp; if (returnCode == ReturnCode.SOLCLIENT_OK) <br>\
                    &nbsp; { <br>\
                    &nbsp; &nbsp; // connected to the Solace message router <br>\
                    &nbsp; } <br>\
                } <br>\
                ',
      },
      {
        protocol: "Compressed SMF",
        name: "Compressed SMF",
        sample:
          '\
                ContextFactoryProperties cfp = new ContextFactoryProperties() <br>\
                { <br>\
                SolClientLogLevel = SolLogLevel.Warning <br>\
                }; <br>\
                cfp.LogToConsoleError(); <br>\
                ContextFactory.Instance.Init(cfp); <br>\
                <br>\
                SessionProperties sessionProps = new SessionProperties() <br>\
                { <br>\
                    &nbsp; Host = "<span class="value">${url}</span>", <br>\
                    &nbsp; VPNName = "<span class="value">${vpn}</span>", <br>\
                    &nbsp; UserName = "<span class="value">${username}</span>", <br>\
                    &nbsp; Password = <span class="value">${password}</span>", <br>\
                    &nbsp; ReconnectRetries = DefaultReconnectRetries, <br>\
                    &nbsp; // The valid range is 1..9, where 1 is less compression (fastest) <br>\
                    &nbsp; // and 9 is most compression (slowest) */ <br>\
                    &nbsp; CompressionLevel = 9 <br>\
                }; <br>\
                <br>\
                using (IContext context = ContextFactory.Instance.CreateContext(new ContextProperties(), null)) <br>\
                using (ISession session = context.CreateSession(sessionProps, HandleMessage, null)) <br>\
                { <br>\
                    &nbsp; ReturnCode returnCode = session.Connect(); <br>\
                    &nbsp; if (returnCode == ReturnCode.SOLCLIENT_OK) <br>\
                    &nbsp; { <br>\
                    &nbsp; &nbsp; // connected to the Solace message router <br>\
                    &nbsp; } <br>\
                } <br>\
                ',
      },
    ],
    conclusion: [
      "At this point your client is connected to your Solace PubSub+ Event Broker",
    ],
  },
  step3: {
    tutorials: [
      {
        id: "publish-subscribe",
        name: "Publish/ <br>\
                        Subscribe",
        logo: "./images/publish-subscribe.svg",
        url:
          "https://tutorials.solace.dev/dotnet/publish-subscribe/",
      },
      {
        name: "Persistence <br>\
                        with Queues",
        logo: "./images/persistence-queues.svg",
        url:
          "https://tutorials.solace.dev/dotnet/persistence-with-queues/",
      },
      {
        name: "Request/ <br>\
                        Reply",
        logo: "./images/request-reply.svg",
        url: "https://tutorials.solace.dev/dotnet/request-reply/",
      },
      {
        name: "Confirmed <br>\
                        Delivery",
        logo: "./images/confirmed-delivery.svg",
        url:
          "https://tutorials.solace.dev/dotnet/confirmed-delivery/",
      },
      {
        name: "Topic to <br>\
                        Queue Mapping",
        logo: "./images/topic-mapping.svg",
        url:
          "https://tutorials.solace.dev/dotnet/topic-to-queue-mapping/",
      },
    ],
  },
}
